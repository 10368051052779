import axios from "axios";

axios.interceptors.request.use((request) => {
  const basicURL = "storage.googleapis.com";
  if (request.url.includes(basicURL)) {
    console.log(request.url, "urls in");
    return request;
  } else {
    console.log(request, "url out");

    return request;
  }
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response, "error.response");

    return Promise.reject(error);
  }
);
export default axios;
