import { toast } from "react-toastify";
import { regExpConditions } from ".";
import { updateCommonStates } from "../store/actions";
import store, { addUserLogoutRequest } from "../store/store";
const dispatch = store.dispatch;
export const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export const showsuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const dateFormatValidator = (value, prevValue) => {
  const val = value.replace("--", "-");
  const regExpWord = /^[0-9-]*$/;
  if (regExpWord.test(val) && val?.length <= 10) {
    if (val.length === 4 && prevValue?.length === 3) return val + "-";
    else if (val.length === 7 && prevValue?.length === 6) return val + "-";
    else return val;
  } else return null;
};

export const regExpCheck = {
  numbersAndSpecialCharactersOnly: (val) => {
    const phoneNumberRegex = /^[^a-z\s]{4,}$/;
    return phoneNumberRegex.test(val);
  },
};

export const LogoutFun = () => {
  dispatch(addUserLogoutRequest(true));
  updateCommonStates('isLogin', false)
};

export const checkValidations = {
  numbersWithDecimal: (val) => {
    const t = val?.replace("..", ".");
    if (
      regExpConditions.numbers.test(t) &&
      (Number(t) || Number(t) === 0 || t === ".")
    )
      return t;
    else return false;
  },
};

export const  showErrorMsg = (err) => {
  console.log(err,'errr');
  showErrorToast(err?.response?.data?.message)
}