import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import FileView from './fileView';
import { ArrowBack, Search, SignalCellularNullTwoTone } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { convertFileSize, dateFormat, getFileNameListAction } from './utils'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: 450
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FileTreeView({ selectFile }) {
  const [open, setOpen] = React.useState(false);
  const [fileNameList, setFileNameList] = React.useState([]);
  const [isLoad, setIsLoad] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [errMsg, setErrMsg] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const reset = () => {
    setFileNameList([]);
    setErrMsg('');
    setIsLoad(false);
    setSelectedFile(null);
    setCurrentPath('');
    setSearch('');

  }
  const handleClose = () => {
    setOpen(false);
    reset()
  };
  const onDoubleClickFolder = (path) => {
    if (!isLoad) {
      getFiles(path)
    }
  }
  const getFiles = (path) => {
    getFileNameListAction(path, setIsLoad).then((data) => {
      const [res, errMessage] = data;
      if (res) {
        setFileNameList(res)
        pathName(res)
      }
      if (errMessage) {
        setErrMsg(errMessage)
        setTimeout(() => setErrMsg(''), 2000)
      }
    });
  }
  const removeFinalPath = (cuPath) => {
    let pathName = cuPath?.split('/');
    if (pathName.length) pathName.splice(pathName.length - 1, 1);
    return pathName?.join('/') || '';
  }
  const pathName = (fileNameList) => {
    if (fileNameList?.length) {
      setCurrentPath(removeFinalPath(fileNameList[0]?.filePath))
    }
    return ''

  }
  const [search, setSearch] = React.useState('');
  const onSearch = () => {
    if (search) {
      getFiles(search);
      setSearch('')
    }
  }
  const onBack = () => {
    if (!isLoad) {
      const t = currentPath?.split('/')?.length
      if (t > 2) {
        const prevPath = removeFinalPath(currentPath);
        getFiles(prevPath);
      }
    }
  }
  const onSelect = () => {
    console.log(selectedFile);
  }
  React.useEffect(() => {
    open && getFiles();
  }, [open])
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='md'
        fullWidth
      >
        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        
        </BootstrapDialogTitle> */}
        <DialogContent dividers>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <FormControl sx={{ width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="search path"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onSearch}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errMsg ? <Typography variant='body2' color="red">{errMsg}</Typography> : null}
            </FormControl>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            {currentPath ?
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <IconButton onClick={onBack}>
                    <ArrowBack sx={{ color: grey[500], mr: 1 }} fontSize={'35'} />
                  </IconButton>
                  <Typography color='GrayText' variant='body2'>{currentPath}</Typography>
                </Box>
              </Box>
              : null}
            {fileNameList?.length ? <Box sx={{ mx: 5 }}>
              {/* <FormControlLabel control={<Checkbox onChange={(e) => {
              if (e.target.checked) setSelectedFile(fileNameList)
              else setSelectedFile([])
            }} />} label="Select All" /> */}
              {(fileNameList?.length === selectedFile?.length) ?
                <Button size='small' onClick={() => setSelectedFile([])}>Unselect all</Button>
                :
                <Button size='small' onClick={() => setSelectedFile(fileNameList)}>Select all</Button>
              }
            </Box> : null}
          </Box>
          {isLoad ?
            <Typography sx={{ mx: 5 }} color="GrayText">loading...</Typography>
            :
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {
                fileNameList?.map(file => (
                  <FileView file={file} setSelectedFile={setSelectedFile} selectedFile={selectedFile} onDoubleClickFolder={onDoubleClickFolder} fileNameList={fileNameList} />
                ))
              }
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Box style={{ float: 'right', flex: 1 }}>
            {/* {selectedFile ? <Typography sx={{ mx: 3 }}  fontSize={12} color="GrayText" variant='body2'>File Size : {convertFileSize(selectedFile?.size)}</Typography> : null} */}
            {(selectedFile?.length === 1) ?
              <Typography sx={{ mx: 3 }} fontSize={12} color="GrayText" variant='body2'>Created On : {dateFormat(selectedFile[0].createdAt)}</Typography> :
              selectedFile?.length > 1 ?
                <Typography sx={{ mx: 3 }} fontSize={12} color="GrayText" variant='body2'>Selected ({selectedFile?.length}) files</Typography> :

                null
            }
          </Box>
          {(selectFile && selectedFile?.length) ? <Button variant='contained' autoFocus onClick={onSelect}>
            Select
          </Button> : null}
          <Button autoFocus variant='contained' color='error' onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
