import { createSlice } from '@reduxjs/toolkit'

export const invoiceForm = createSlice({
    name: 'invoiceForm',
    initialState: {
        userData: { empId: null, count: null },
        tableData: [],
        compareTableData: [],
        prevForm: null,
        nextForm: null,
        currentPosition: 0,
        firstRender:true

    },
    reducers: {
        updateInvoiceFormReducer: (state, action) => {
            state[action.payload.name] = action.payload.value
        },
    },
});

export const { updateInvoiceFormReducer } = invoiceForm.actions;

export default invoiceForm.reducer