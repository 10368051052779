// if split count 100, array of length 105 dont need to seperate 5 files from that,so,below grace value decide to seperate file
export const SPLIT_GRACE_VALUE = 10;
export const COLUMN_NAMES = {

    SNo: {
        name: ['S.No'],
        columnPosition: 'A',
    },
    DSNo: {
        name: ['D.S.No'],
        columnPosition: 'B',
    },
    TYPE: {
        name: ['TYPE'],
        columnPosition: 'C',
    },
    FOLDER: {
        name: ['FOLDER'],
        columnPosition: 'D',
    },
    FileName: {
        name: ['File Name'],
        columnPosition: 'E',
    },
    imagePath: {
        name: ['image Path'],
        columnPosition: 'F',
    },
    issuedDate: {
        name: ['issuedDate'],
        columnPosition: 'G',
    },
    dueDate: {
        name: ['dueDate'],
        columnPosition: 'H',
    },
    amount: {
        name: ['amount'],
        columnPosition: 'I',
    },
    invoiceNumber: {
        name: ['invoiceNumber'],
        columnPosition: 'J',
    },
    terms: {
        name: ['terms'],
        columnPosition: 'K',
    },
    poNumber: {
        name: ['poNumber'],
        columnPosition: 'L',
    },
    amount_1: {
        name: ['amount_1'],
        columnPosition: 'M',
    },
    memo: {
        name: ['memo'],
        columnPosition: 'N',
    },
    fileName: {
        name: ['fileName'],
        columnPosition: 'O',
    },
    name: {
        name: ['name'],
        columnPosition: 'P',
    },
    email: {
        name: ['email'],
        columnPosition: 'Q',
    },
    phone: {
        name: ['phone'],
        columnPosition: 'R',
    },
    taxId: {
        name: ['taxId'],
        columnPosition: 'S',
    },
    customerAccount: {
        name: ['customerAccount'],
        columnPosition: 'T',
    },
    Comment: {
        name: ['Comment'],
        columnPosition: 'U',
    },
    CoderName: {
        name: ['CoderName'],
        columnPosition: 'V',
    },
    Remarks: {
        name: ['Remarks'],
        columnPosition: 'W',
    },
    Comments: {
        name: ['Comments'],
        columnPosition: 'X',
    },
    Description: {
        name: ['Description'],
        columnPosition: 'Y',
    },
}

export const EMPLOYEE_NAME_LIST = ['"Koteeswari S,Balasubramanian A,Shobana D,Mohankumar M,Srinivasan P,Sarguna E,Karthikeyan M,Rekha M,Parameshwari S,Rathi Meena C,Gajalakshmi A,Sathy Moorthy N,Sakthi S,Gowri M,Ramesh P,Imran Ahmed Vm,Manjula G,Manicka Vasanth R"']
export const TRACKER_STATUS = ['"NA,YTS,WIP,DONE"']
export const COLUMN_DETAILS = {
    columnData: {
        SNo: {
            name: 'S.No',
            columnPosition: 'A',
            columnNo:0,
        },
        DSNo: {
            name: 'D.S.No',
            columnPosition: 'B',
            columnNo:1,
        },
        TYPE: {
            name: 'TYPE',
            columnPosition: 'C',
            columnNo:2,
        },
        FOLDER: {
            name: 'FOLDER',
            columnPosition: 'D',
            columnNo:3,
        },
        FileName: {
            name: 'File Name',
            columnPosition: 'E',
            columnNo:4,
        },
        imagePath: {
            name: 'Image Path',
            columnPosition: 'F',
            columnNo:5,
        },
        issuedDate: {
            name: 'issuedDate',
            columnPosition: 'G',
            columnNo:6,
        },
        dueDate: {
            name: 'dueDate',
            columnPosition: 'H',
            columnNo:7,
        },
        amount: {
            name: 'amount',
            columnPosition: 'I',
            columnNo:8,
        },
        invoiceNumber: {
            name: 'invoiceNumber',
            columnPosition: 'J',
            columnNo:9,
        },
        terms: {
            name: 'terms',
            columnPosition: 'K',
            columnNo:10,
        },
        poNumber: {
            name: 'poNumber',
            columnPosition: 'L',
            columnNo:11,
        },
        amount_1: {
            name: 'amount',
            columnPosition: 'M',
            validationName: 'amount_1',
            columnNo:12,
        },
        memo: {
            name: 'memo',
            columnPosition: 'N',
            columnNo:13,
        },
        fileName: {
            name: 'fileName',
            columnPosition: 'O',
            columnNo:14,
        },
        name: {
            name: 'name',
            columnPosition: 'P',
            columnNo:15,
        },
        email: {
            name: 'email',
            columnPosition: 'Q',
            columnNo:16,
        },
        phone: {
            name: 'phone',
            columnPosition: 'R',
            columnNo:17,
        },
        taxId: {
            name: 'taxId',
            columnPosition: 'S',
            columnNo:18,
        },
        customerAccount: {
            name: 'customerAccount',
            columnPosition: 'T',
            columnNo:19,
        },
        Comment: {
            name: 'Comment',
            columnPosition: 'U',
            columnNo:20,
        },
        CoderName: {
            name: 'CoderName',
            columnPosition: 'V',
            columnNo:21,
        },
        Remarks: {
            name: 'Remarks',
            columnPosition: 'W',
            columnNo:22,
        },
        AdditionalFields: {
            name: 'Additional Fields',
            columnPosition: 'X',
            columnNo:23,
        },
    },
    getcolumnNames: function () {
        const t = Object.keys(this.columnData).map(data => this.columnData[data].name);
        console.log(t, '234w65eruyftiguhkj');
        return t
    },
    getcolumnNamesForFinalSheetGeneration: function () {
        let t = [Object.keys(this.columnData).map(data => this.columnData[data].name)];

        console.log(t, '234w65eruyftiguhkj');
        return t
    }
}
export const COLUMN_DETAILS2 = {
    columnData: {
        SNo: {
            name: 'S.No',
            columnPosition: 'A',
            columnNo:0,
        },
        DSNo: {
            name: 'D.S.No',
            columnPosition: 'B',
            columnNo:1,
        },
        TYPE: {
            name: 'TYPE',
            columnPosition: 'C',
            columnNo:2,
        },
        FOLDER: {
            name: 'FOLDER',
            columnPosition: 'D',
            columnNo:3,
        },
        FileName: {
            name: 'File Name',
            columnPosition: 'E',
            columnNo:4,
        },
        imagePath: {
            name: 'Image Path',
            columnPosition: 'F',
            columnNo:5,
        },
        SourceFileName: {
            name: 'SourceFileName',
            columnPosition: 'G',
            columnNo:6,
        },
        VendorName: {
            name: 'VendorName',
            columnPosition: 'H',
            columnNo:7,
        },
        InvoiceNumber: {
            name: 'InvoiceNumber',
            columnPosition: 'I',
            columnNo:8,
        },
        InvoiceDate: {
            name: 'InvoiceDate',
            columnPosition: 'J',
            columnNo:9,
        },
        PONumber: {
            name: 'PONumber',
            columnPosition: 'K',
            columnNo:10,
        },
        poNumber: {
            name: 'poNumber',
            columnPosition: 'L',
            columnNo:11,
        },
        Tax: {
            name: 'Tax',
            columnPosition: 'M',
            columnNo:12,
        },
        OtherTax: {
            name: 'OtherTax',
            columnPosition: 'N',
            columnNo:13,
        },
        InvoiceFreight: {
            name: 'InvoiceFreight',
            columnPosition: 'O',
            columnNo:14,
        },
        TaxFreight: {
            name: 'TaxFreight',
            columnPosition: 'P',
            columnNo:15,
        },
        TotalAmountDue: {
            name: 'TotalAmountDue',
            columnPosition: 'Q',
            columnNo:16,
        },
        Terms: {
            name: 'Terms',
            columnPosition: 'R',
            columnNo:17,
        },
        DueDate: {
            name: 'DueDate',
            columnPosition: 'S',
            columnNo:18,
        },
        TaxID: {
            name: 'TaxID',
            columnPosition: 'T',
            columnNo:19,
        },
        VendorEmail: {
            name: 'VendorEmail',
            columnPosition: 'U',
            columnNo:20,
        },
        VendorPhoneNumber: {
            name: 'VendorPhoneNumber',
            columnPosition: 'V',
            columnNo:21,
        },
        CustomerAccountNumber: {
            name: 'CustomerAccountNumber',
            columnPosition: 'W',
            columnNo:22,
        },
        Comments: {
            name: 'Comments',
            columnPosition: 'X',
            columnNo:22,
        },
        AdditionalFields: {
            name: 'Additional Fields',
            columnPosition: 'Y',
            columnNo:23,
        },
    },
    getcolumnNames: function () {
        const t = Object.keys(this.columnData).map(data => this.columnData[data].name);
        console.log(t, '234w65eruyftiguhkj');
        return t
    },
    getcolumnNamesForFinalSheetGeneration: function () {
        let t = [Object.keys(this.columnData).map(data => this.columnData[data].name)];

        console.log(t, '234w65eruyftiguhkj');
        return t
    }
}
// export const TRACKER_COLUMN_DETAILS = ['S.No', 'Type', 'Folder', 'FileName', 'Doc.count', 'Name', 'Start','End','Hrs','Line','Com','Bal','Status']
export const TRACKER_COLUMN_DETAILS = {
    columnData: {
        sNo: {
            name: 'S.No',
            position: 'A'
        },
        Type: {
            name: 'Type',
            position: 'B'
        },
        Folder: {
            name: 'Folder',
            position: 'C'
        },
        FileName: {
            name: 'FileName',
            position: 'D'
        },
        count: {
            name: 'Doc.count',
            position: 'E'
        },
        Name: {
            name: 'Name',
            position: 'F'
        },
        Start: {
            name: 'Start',
            position: 'G'
        },
        End: {
            name: 'End',
            position: 'H'
        },
        Hrs: {
            name: 'Hrs',
            position: 'I'
        },
        Line: {
            name: 'Line',
            position: 'J'
        },
        Com: {
            name: 'Com',
            position: 'K'
        },
        Bal: {
            name: 'Bal',
            position: 'L'
        },
        status: {
            name: 'Status',
            position: 'M'
        },
        Name2: {
            name: 'Name',
            position: 'N'
        },
        Start2: {
            name: 'Start',
            position: 'O'
        },
        End2: {
            name: 'End',
            position: 'P'
        },
        Hrs2: {
            name: 'Hrs',
            position: 'Q'
        },
        Line2: {
            name: 'Line',
            position: 'R'
        },
        Com2: {
            name: 'Com',
            position: 'S'
        },
        Bal2: {
            name: 'Bal',
            position: 'T'
        },
        status2: {
            name: 'Status',
            position: 'U'
        },
    },
    getcolumnNames: function () {
        const t = Object.keys(this.columnData).map(data => this.columnData[data].name);
        return t
    }
}
export const HEADER_LIST = [
    'S.No',
    'D.S.No',
    'TYPE',
    'FOLDER',
    'File Name',
    'issuedDate',
    'dueDate',
    'amount',
    'invoiceNumber',
    'terms',
    'poNumber',
    'amount_1',
    'memo',
    'fileName',
    'name',
    'email',
    'phone',
    'taxId',
    'customerAccount',
    'Comment',
    'CoderName',
    'Remarks',
    'Additional Fields'
];
export const HEADER_LIST_ACTUAL = [
    [
        'S.No',
        'D.S.No',
        'TYPE',
        'FOLDER',
        'File Name',
        'issuedDate',
        'dueDate',
        'amount',
        'invoiceNumber',
        'terms',
        'poNumber',
        'amount',
        'memo',
        'fileName',
        'name',
        'email',
        'phone',
        'taxId',
        'customerAccount',
        'Comment',
        'CoderName',
        'Remarks',
        'Additional Fields'
    ]
];