import { createSlice } from "@reduxjs/toolkit";

export const jobForm = createSlice({
  name: "jobForm",
  initialState: {
    jobList: [],
    selectedJob: {},
  },
  reducers: {
    updateJobFormReducer: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { updateJobFormReducer } = jobForm.actions;

export default jobForm.reducer;
