import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import logo from "../asstes/images/logoback.png";

function Footer() {
  const { navBarOpen } = useSelector((state) => state.common);
  return (
    <div
      style={{
        marginLeft: navBarOpen ? "240px" : "0rem",
      }}
    >
      <Box>
        <Box sx={{ position: "fixed", bottom: 20, right: 20 }}>
          <img src={logo} style={{ height: 20 }} alt="logo" />
        </Box>
      </Box>
    </div>
  );
}

export default Footer;
