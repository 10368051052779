import {
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    FormControlLabel,
    IconButton,
    InputBase,
    Paper,
    Radio,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import file from "../../asstes/images/file.png";
import upload from "../../asstes/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import FileSaver from "file-saver";
import { COLUMN_DETAILS, HEADER_LIST_ACTUAL } from "../split/config";
import { colorForNameValidation, columnCheck, COLUMNS_LIST, keysToKeepinAmountValidationSheet, keysToKeepinAmountValidationSheetFrColumn, keysToKeepinNameValidationSheetAndColumn, NAME_VALIDATION_COLUMN, NAME_VALIDATION_INSTRUCTION, qcValidation } from "./validation";
import { useLocation } from "react-router-dom";
import moment from "moment";
import FileTreeView from "../../components/fileTree/fileTree";


var _ = require('lodash');
function QcFileScreen() {
    const inputRef = useRef(null);
    const drop = useRef(null);
    const location = useLocation();
    const merge = location?.pathname === '/merge';
    const [stateValues, setStateValues] = useState({});
    const [load, setLoad] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState(['Option 1', 'Option 2', 'Option 3']);
    const fieldContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
    };
    const ButtonStyle = {
        marginTop: 1,
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#3c82ea",
        borderRadius: "15px",
        px: 3,
    };
    const iconStyle = {
        color: "#81b3fd",
    };
    const handelclearfile = () => {
        setLoad(false);
        drop.current.value = "";
        inputRef.current.value = "";
        setStateValues({});
    };
    const showErrorMsg = (msg) => {
        setIsError(msg);
        // setTimeout(() => setIsError(false), 3000)
    }
    const convertExcelToJson = async (file, callBack, mergeSheet) => {
        setLoad(true)
        let final = [];
        let error = [];
        try {
            new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    // const wsName = wb.SheetNames[0];
                    const wsName = wb.SheetNames[0];
                    console.log(wsName, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                    const ws = wb.Sheets[wsName];
                    const data = XLSX.utils.sheet_to_json(ws, { defval: null })
                    if (mergeSheet) {
                        const isFind = wb.SheetNames?.findIndex(i => i === "amountValidation");
                        const isFind2 = wb.SheetNames?.findIndex(i => i === "nameValidation");
                        const isFind3 = wb.SheetNames?.findIndex(i => i === "restricted_copied_data_sheet");
                        let data2 = [];
                        let data3 = [];
                        let backup = [];
                        if (isFind < 0 && (selectedValue === "a" || selectedValue === "c")) {
                            setLoad(false);
                            showErrorMsg('amountValidation sheet not available');
                            return
                        } else {
                            const wsName = wb.SheetNames[isFind];
                            const ws = wb.Sheets[wsName];
                            data2 = XLSX.utils.sheet_to_json(ws, { defval: null });
                        }
                        if (isFind2 < 0 && (selectedValue === "b" || selectedValue === "c")) {
                            setLoad(false);
                            showErrorMsg('nameValidation sheet not available');
                            return
                        } else {
                            const wsName2 = wb.SheetNames[isFind2];
                            const ws2 = wb.Sheets[wsName2];
                            data3 = XLSX.utils.sheet_to_json(ws2, { defval: null });
                            // get backup data sheet
                            const wsName3 = wb.SheetNames[isFind3];
                            const ws3 = wb.Sheets[wsName3];
                            backup = XLSX.utils.sheet_to_json(ws3, { defval: null });
                            // data3 = temp.map(i => {
                            //     const t = { ...i };
                            //     t['name'] = t['name']?.replace(/<e(1)?>/g, "")
                            //     t['phone'] = t['phone']?.replace(/<e(1)?>/g, "")
                            //     t['email'] = t['email']?.replace(/<e(1)?>/g, "")
                            //     t['taxId'] = t['taxId']?.replace(/<e(1)?>/g, "")
                            //     return t
                            // })
                        }



                        resolve([data, data2, data3, backup]);
                    }
                    else resolve([data, [], [], []]);
                    // resolve(data);
                    fileReader.onerror = (e) => {
                        reject(e)
                    }
                }
            }).then(async (data32) => {
                const [res, res2, res3, backup] = data32;
                if (!res?.length) {
                    showErrorMsg('File is Empty');
                    setLoad(false)
                }
                const result = await columnCheck(res, showErrorMsg, setLoad, true);
                if (!result) return;
                let datas = res;
                if (mergeSheet) {
                    const t = [];
                    const updatedRes = res.map((item, index) => {
                        const current = { ...item }
                        const isFind = res2?.findIndex(i => (i['D.S.No'] == current['D.S.No']) && (i['S.No'] == current['S.No']) && (i['File Name'] === current['File Name']));
                        const isFindName = res3?.findIndex(i => (i['D.S.No'] == current['D.S.No']) && (i['S.No'] == current['S.No']) && (i['File Name'] === current['File Name']));
                        if (isFindName >= 0 && (selectedValue === "b" || selectedValue === "c")) {
                            const tempObj = res3[isFindName];
                            if (backup?.length) {
                                const backupObj = backup[index];
                                if (backupObj[COLUMN_DETAILS.columnData.SNo.name] === current[COLUMN_DETAILS.columnData.SNo.name]) {
                                    if (backupObj[COLUMN_DETAILS.columnData.name.name] !== tempObj[COLUMN_DETAILS.columnData.name.name]) current['name'] = tempObj['name']
                                    if (backupObj[COLUMN_DETAILS.columnData.email.name] !== tempObj[COLUMN_DETAILS.columnData.email.name]) current['email'] = tempObj['email']
                                    if (backupObj[COLUMN_DETAILS.columnData.phone.name] !== tempObj[COLUMN_DETAILS.columnData.phone.name]) current['phone'] = tempObj['phone']
                                    if (backupObj[COLUMN_DETAILS.columnData.taxId.name] !== tempObj[COLUMN_DETAILS.columnData.taxId.name]) current['taxId'] = tempObj['taxId']
                                } else {
                                    current['name'] = tempObj['name']
                                    current['email'] = tempObj['email']
                                    current['phone'] = tempObj['phone']
                                    current['taxId'] = tempObj['taxId']
                                }

                            } else {
                                current['name'] = tempObj['name']
                                current['email'] = tempObj['email']
                                current['phone'] = tempObj['phone']
                                current['taxId'] = tempObj['taxId']
                            }

                        }
                        // Additional field - amount row
                        if (isFind >= 0 && (selectedValue === "a" || selectedValue === "c")) {
                            const tempObj = res2[isFind];
                            const normalProcess = () => {
                                current['amount'] = tempObj['amount'];
                                current['amount_1'] = tempObj['amount_1'];
                                current['memo'] = tempObj['memo'];
                                const keys = Object.keys(res2[isFind]).filter(i => i.includes('__EMPTY'));
                                keys.forEach(item => {
                                    if (current['Additional Fields'] === "amount" ||
                                        current['Additional Fields'] === "memo" ||
                                        current['Additional Fields'] === "quantity" ||
                                        current['Additional Fields'] === "Cost"
                                    ) current[item] = res2[isFind][item];
                                });
                            }
                            if (backup?.length) {
                                const backupObj = backup[index];
                                if ((backupObj[COLUMN_DETAILS.columnData.SNo.name] === current[COLUMN_DETAILS.columnData.SNo.name])) {
                                    if (backupObj['amount'] !== tempObj['amount']) current['amount'] = tempObj['amount'];
                                    if (backupObj['amount_1'] !== tempObj['amount_1']) current['amount_1'] = tempObj['amount_1'];
                                    if (backupObj['memo'] !== tempObj['memo']) current['memo'] = tempObj['memo'];
                                    const key1 = Object.keys(current).filter(i => i.includes('__EMPTY'));
                                    const key2Keys = []
                                    const key2 = (Object.keys(res2[isFind]).filter(i => {
                                        if (i.includes('__EMPTY')) {
                                            key2Keys.push(i)
                                            return true
                                        }
                                    })).map(keyName => res2[isFind][keyName]);
                                    const key3 = Object.keys(backupObj).filter(i => i.includes('__EMPTY')).map(keyName => backupObj[keyName]);
                                    const removeFalseValue = (arr) => {
                                        let values = arr?.reverse();
                                        while (values.length > 0 && !values[0]) {
                                            values.shift();
                                        }
                                        return values.reverse();
                                    }
                                    const t1 = removeFalseValue(key2);
                                    const t2 = removeFalseValue(key3);
                                    if (key2?.toString() !== key3?.toString()) {
                                        key1.forEach(item => delete current[item])
                                        key2Keys.forEach(item => current[item] = res2[isFind][item]);
                                    }

                                } else normalProcess();
                            } else normalProcess();
                            return current;
                        }
                        else {
                            // Additional field memo row only place amount_1 & memo
                            const isFind2 = res2?.findIndex(i => (i['S.No'] === current['S.No']) && (i['File Name'] === current['File Name']));
                            if (isFind2 >= 0) {
                                const tempObj = res2[isFind2];
                                const normalProcess = () => {
                                    current['amount_1'] = tempObj['amount_1'];
                                    current['memo'] = tempObj['memo'];
                                }
                                if (backup?.length) {
                                    const backupObj = backup[index];
                                    if (backupObj[COLUMN_DETAILS.columnData.SNo.name] === current[COLUMN_DETAILS.columnData.SNo.name]) {
                                        if (backupObj['amount_1'] !== tempObj['amount_1']) current['amount_1'] = tempObj['amount_1'];
                                        if (backupObj['memo'] !== tempObj['memo']) current['memo'] = tempObj['memo'];
                                    } else normalProcess();
                                } else normalProcess();
                            }
                            return current;
                        }
                    });
                    // datas = updatedRes;
                    callBack && callBack(updatedRes, [], [], [], [], [], file?.name);
                }
                else if (datas?.length) {
                    const { modifiedData, error, warning, filteredAmountSheet, finalMatchingNamesData, generalError } = await qcValidation(datas);
                    console.log(modifiedData, ' modifiedData76r5dtxtf6tycfgyvguyyguvu');
                    console.log(error, 'error');
                    console.log(warning, ' warning');
                    callBack && callBack(modifiedData, error, warning, filteredAmountSheet, finalMatchingNamesData, generalError, file?.name);
                }
                // console.log(res, 'szdxfcgvhbjn');

                // setConvertedFile((prev) => {
                //     return [...prev, ...res]
                // })
            }).catch((err) => {
                console.log(err, 'szdxfcgvhbjn');
            })
        } catch (error) {
            console.log(error, 'szdxfcgvhbjn');
            setLoad(false);
        }
        // setLoad(false);

    }
    const handleSubmit = async (mergeSheet) => {
        if (Object.keys(stateValues).length) {
            await convertExcelToJson(stateValues.key1, async (modifiedData, error, warning, amountMismatchArr, finalMatchingNamesData, generalError, fileName) => {
                console.log(modifiedData, 'modifiedDatamodifiedDatamodifiedData');
                const usingXLSX = async () => {
                    const ws = XLSX.utils.json_to_sheet([...modifiedData], { origin: 'A2', skipHeader: true });
                    XLSX.utils.sheet_add_aoa(ws, [COLUMN_DETAILS.getcolumnNames()], { origin: 'A1' });
                    const wsCopy = XLSX.utils.json_to_sheet([...modifiedData], { origin: 'A2', skipHeader: true });
                    XLSX.utils.sheet_add_aoa(wsCopy, [COLUMN_DETAILS.getcolumnNames()], { origin: 'A1' });
                    wsCopy["!protect"] = 1

                    // condition for merge screen & qc
                    let wb;
                    if (mergeSheet) {
                        wb = { Sheets: { data: ws, restricted_copied_data_sheet: wsCopy }, SheetNames: ["data", "restricted_copied_data_sheet"] };
                    }
                    else {
                        const ws2 = XLSX.utils.json_to_sheet([...error, ...warning]);
                        const ws3 = XLSX.utils.json_to_sheet([...amountMismatchArr], { origin: 'A2', skipHeader: true });
                        const ws4 = XLSX.utils.json_to_sheet([...finalMatchingNamesData], { origin: 'A2', skipHeader: true });
                        const ws5 = XLSX.utils.json_to_sheet([...NAME_VALIDATION_INSTRUCTION]);
                        const ws6 = XLSX.utils.json_to_sheet([
                            ...generalError

                        ]);
                        XLSX.utils.sheet_add_aoa(ws3, [keysToKeepinAmountValidationSheetFrColumn], { origin: 'A1' });
                        XLSX.utils.sheet_add_aoa(ws4, [keysToKeepinNameValidationSheetAndColumn], { origin: 'A1' });
                        wb = {
                            Sheets: {
                                data: ws,
                                error: ws2,
                                amountValidation: ws3,
                                nameValidation: ws4,
                                nameValidation_instruction: ws5,
                                generalError: ws6,
                                restricted_copied_data_sheet: wsCopy

                            }, SheetNames: ["data",
                                "error",
                                "amountValidation",
                                "nameValidation",
                                "nameValidation_instruction",
                                "generalError",
                                "restricted_copied_data_sheet"
                            ]
                        };
                    }

                    // //    change format to text
                    // const worksheet = wb.Sheets['data'];
                    // // Get the range of cells in the sheet
                    // const range = XLSX.utils.decode_range(worksheet['!ref']);
                    // // Iterate through each cell in the range
                    // for (let row = range.s.r; row <= range.e.r; row++) {
                    //     for (let col = range.s.c; col <= range.e.c; col++) {
                    //         const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                    //         const cellValue = worksheet[cellAddress]?.v; // Get the cell value
                    //         if (cellValue === undefined || cellValue === null) {
                    //             worksheet[cellAddress] = {
                    //                 t: 's',
                    //                 v: '',
                    //                 z: '@'
                    //             };
                    //         }
                    //         const fmt = '@';
                    //         worksheet[cellAddress].z = fmt;

                    //     }
                    // }

                    const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array", cellStyles: true });
                    const data = await new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, `QcFile_${fileName}`);
                }
                const usingExceljs = () => {
                    // Create a new workbook
                    const workbook = new Workbook();
                    // add worksheets
                    const worksheet = workbook.addWorksheet('data');
                    worksheet.addRow(COLUMN_DETAILS.getcolumnNames());

                    if (!mergeSheet) {
                        const worksheetError = workbook.addWorksheet('error');
                        const worksheetAmountValidation = workbook.addWorksheet('amountValidation');
                        const worksheetNameValidation = workbook.addWorksheet('nameValidation');
                        const worksheetNameValidationIns = workbook.addWorksheet('nameValidation_instruction');
                        const worksheetGeneralError = workbook.addWorksheet('General_Error');
                        worksheetError.addRow(['S.No', 'D.S.No', 'FileName', 'imagePath', 'FieldName', 'keyedValue', 'updatedValue', 'Msg', 'Type', 'status', 'checked'])
                        worksheetGeneralError.addRow(['S.No', 'D.S.No', 'FileName', 'imagePath', 'FieldName', 'keyedValue', 'updatedValue', 'Msg', 'Type', 'status', 'checked'])
                        worksheetAmountValidation.addRow(keysToKeepinAmountValidationSheetFrColumn)
                        worksheetNameValidation.addRow(keysToKeepinNameValidationSheetAndColumn)
                        const t = [...error, ...warning]
                        t?.map(obj => {
                            const row = worksheetError.addRow(Object.values(obj));
                            row.eachCell({ includeEmpty: true }, (cell) => {
                                cell.numFmt = '@'; // Set cell format to text
                            });
                        })
                        amountMismatchArr.map(obj => {
                            const row = worksheetAmountValidation.addRow(Object.values(obj));
                            row.eachCell({ includeEmpty: true }, (cell) => {
                                cell.numFmt = '@'; // Set cell format to text

                            });
                        })
                        finalMatchingNamesData.map(obj => {
                            // const emptyFilter = Object.values(obj).map(i => i || '');
                            const row = worksheetNameValidation.addRow(Object.values(obj));
                            row.eachCell({ includeEmpty: true }, (cell) => {
                                cell.numFmt = '@'; // Set cell format to text
                            });
                            if (obj[NAME_VALIDATION_COLUMN.name] || obj[NAME_VALIDATION_COLUMN.email] || obj[NAME_VALIDATION_COLUMN.phone] || obj[NAME_VALIDATION_COLUMN.taxId]) {
                                const tName = ((obj[NAME_VALIDATION_COLUMN.name] || '') + ',' + (obj[NAME_VALIDATION_COLUMN.email] || '') + ',' + (obj[NAME_VALIDATION_COLUMN.phone] || '') + ',' + (obj[NAME_VALIDATION_COLUMN.taxId] || ''))?.split(',');
                                tName && tName.forEach(type => {
                                    const splitName = type?.split('_');
                                    if (splitName?.length > 1) {
                                        const columnName = splitName.at(-1); // The column name you want to retrieve the cell from
                                        const typeNo = splitName.at(1);
                                        const columnIndex = columnName === COLUMN_DETAILS.columnData.name.name ? 4 :
                                            columnName === COLUMN_DETAILS.columnData.email.name ? 5 :
                                                columnName === COLUMN_DETAILS.columnData.phone.name ? 6 :
                                                    columnName === COLUMN_DETAILS.columnData.taxId.name ? 7 : 1
                                        const cell = row.getCell(columnIndex);
                                        console.log(typeNo, 'fyguvhjbknjkkllnk');
                                        cell.fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: colorForNameValidation(typeNo, splitName?.length === 2) }
                                        };
                                    }

                                })
                            }
                            if (obj[NAME_VALIDATION_COLUMN.emptyFields]) {
                                const fields = obj[NAME_VALIDATION_COLUMN.emptyFields].split(',');
                                fields.forEach(columnName => {
                                    const columnIndex = columnName === COLUMN_DETAILS.columnData.name.name ? 4 :
                                        columnName === COLUMN_DETAILS.columnData.email.name ? 5 :
                                            columnName === COLUMN_DETAILS.columnData.phone.name ? 6 :
                                                columnName === COLUMN_DETAILS.columnData.taxId.name ? 7 : 1
                                    const cell = row.getCell(columnIndex);
                                    if (!cell.fill) {
                                        cell.fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'D3D3D3' }
                                        };
                                    }

                                })
                            }
                        })
                        NAME_VALIDATION_INSTRUCTION.map(obj => {
                            const row = worksheetNameValidationIns.addRow(Object.values(obj));
                            row.eachCell({ includeEmpty: true }, (cell) => {
                                cell.numFmt = '@'; // Set cell format to text
                            });
                        })
                        generalError.map(obj => {
                            const row = worksheetGeneralError.addRow(Object.values(obj));
                            row.eachCell({ includeEmpty: true }, (cell) => {
                                cell.numFmt = '@'; // Set cell format to text
    
                            });
                        })
                    }

                    const worksheetBackup = workbook.addWorksheet('restricted_copied_data_sheet');
                    worksheetBackup.addRow(COLUMN_DETAILS.getcolumnNames());
                    modifiedData?.map(obj => {
                        const row = worksheet.addRow(Object.values(obj));
                        const row2 = worksheetBackup.addRow(Object.values(obj));
                        row.eachCell({ includeEmpty: true }, (cell) => {
                            cell.numFmt = '@'; // Set cell format to text

                        });
                        row2.eachCell({ includeEmpty: true }, (cell) => {
                            cell.numFmt = '@'; // Set cell format to text

                        });
                    })
                    worksheetBackup.protect();
                    workbook.xlsx.writeBuffer().then((data) => {
                        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        let finalName = fileName?.split('.')[0];
                        try {
                            if (fileName && fileName?.includes('Qc_')) {
                                const t = fileName?.split('Qc_');
                                if (t.length) {
                                    let t2 = t[1]?.split('.')[0]?.split('_D');
                                    if (t2?.length)
                                        finalName = t2[0]
                                }
                            }
                        } catch (error) {

                        }

                        console.log(`Qc_${finalName}_D${moment().format('DD') + '_' + moment().format('MMM_YY') + '_' + moment().format('hh:mm_A')}`, 'finalNamefinalName');
                        FileSaver.saveAs(blob, `Qc_${finalName}_D${moment().format('DD') + '_' + moment().format('MMM_YY') + '_T' + moment().format('hh:mm_A')}`);
                    })
                }
                usingExceljs()
                // usingXLSX()
                setStateValues({});
                drop.current.value = "";
                inputRef.current.value = "";
                setLoad(false);
            }, mergeSheet)

        } else showErrorMsg('Kindly Select File');


    }
    const hangelFile = (e, sort) => {
        setLoad(true);
        const file = { ...e.target?.files };
        setStateValues({ key1: file[0] })
        setLoad(false);
        setIsError(false);
    };
    const [isError, setIsError] = useState(false);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const handelSubmit = async () => {
        console.log(stateValues, 'sdxfgchvjbkn');
    }
    React.useEffect(() => {
        drop.current.addEventListener("dragover", handleDragOver);
        drop.current.addEventListener("drop", handleDrop);
        return () => {
            drop?.current?.removeEventListener("dragover", handleDragOver);
            drop?.current?.removeEventListener("drop", handleDrop);
        };
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '12345');
            hangelFile({ target: { files: files } })
            // setfirstkey1(files[0]);

        }
    };
    const downloadTemplate = async () => {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Sheet1');
        let headerRow = worksheet.addRow(COLUMNS_LIST);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'Qc_Template');
        })
    }
    const [selectedValue, setSelectedValue] = useState('c');
    return (
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
            <Box sx={{ pt: 2 }}>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Lato",
                        fontSize: "1.5rem",
                        p: 1,
                    }}
                >
                    {merge ? 'Amount Validation Sheet Merge' : 'QCH'}
                </Typography>
                {!merge ? <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={downloadTemplate}>
                        <DownloadIcon fontSize={'20'} /><Typography>Download Template</Typography>
                    </IconButton>

                </Box> : null}
                <Card
                    sx={{ p: 4, backgroundColor: "#f0fefc", border: "1px solid #3c82ea" }}
                >
                    <Box ref={drop} sx={fieldContainer}>
                        {" "}
                        {/* <Typography sx={labelStyle}>Key1</Typography> */}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="file"
                                accept=".xlsx"
                                id="select-image"
                                style={{ display: "none" }}
                                ref={inputRef}
                                onChange={(e) => hangelFile(e, true)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>

                                {stateValues.key1 ?
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Lato",
                                                textAlign: "justify",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {stateValues?.key1?.name}
                                        </Typography>
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                            onClick={() => {
                                                handelclearfile();
                                            }}
                                        />
                                    </Box> : null}

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={fieldContainer}>
                        {/* <Typography sx={labelStyle}></Typography> */}
                        {load ? <Button
                            variant="contained"
                            size="small"
                            component="span"
                            onClick={() => handelSubmit()}
                            sx={{
                                marginTop: 1,
                                textTransform: "capitalize",
                                backgroundColor: "#3c82ea",
                                fontFamily: "Lato",
                                borderRadius: "15px",
                                px: 8,
                                py: 1.5,
                            }}
                        >
                            <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} />
                        </Button>
                            :
                            merge ?
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <FormControlLabel control={
                                            <Radio
                                                checked={selectedValue === 'a'}
                                                onChange={(e) => setSelectedValue(e.target.value)}
                                                value="a"
                                                label="Amount"
                                                name="radio-buttons"
                                                slotProps={{ input: { 'aria-label': 'A' } }}
                                            />
                                        }
                                            label="Amount" />
                                        <FormControlLabel control={
                                            <Radio
                                                checked={selectedValue === 'b'}
                                                onChange={(e) => setSelectedValue(e.target.value)}
                                                value="b"
                                                label="Name"
                                                name="radio-buttons"
                                                slotProps={{ input: { 'aria-label': 'B' } }}
                                            />
                                        }
                                            label="Name" />
                                        <FormControlLabel control={
                                            <Radio
                                                checked={selectedValue === 'c'}
                                                onChange={(e) => setSelectedValue(e.target.value)}
                                                value="c"
                                                label="Both"
                                                name="radio-buttons"
                                                slotProps={{ input: { 'aria-label': 'B' } }}
                                            />
                                        }
                                            label="Both" />

                                    </Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        onClick={() => handleSubmit(true)}
                                        sx={{
                                            marginTop: 1,
                                            textTransform: "capitalize",
                                            backgroundColor: "#3c82ea",
                                            fontFamily: "Lato",
                                            borderRadius: "15px",
                                            px: 8,
                                            py: 1.5,
                                            mx: 2
                                        }}
                                    >
                                        Merge
                                    </Button>
                                </Box>

                                : <Button
                                    variant="contained"
                                    size="small"
                                    component="span"
                                    onClick={() => handleSubmit()}
                                    sx={{
                                        marginTop: 1,
                                        textTransform: "capitalize",
                                        backgroundColor: "#3c82ea",
                                        fontFamily: "Lato",
                                        borderRadius: "15px",
                                        px: 8,
                                        py: 1.5,
                                    }}
                                >
                                    Check
                                </Button>

                        }

                        {/* <FileTreeView selectFile /> */}
                    </Box>

                    {isError ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Alert severity="error">{isError}</Alert>
                            <CloseIcon
                                sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                onClick={() => {
                                    setIsError(false);
                                }}
                            />
                        </Box> : null}
                </Card>
            </Box>
        </Box>
    );
}

export default QcFileScreen;
