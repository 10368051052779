import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    IconButton,
    InputBase,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import file from "../asstes/images/file.png";
import upload from "../asstes/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as XLSX from 'xlsx';
import CustomizedTables from "../components/table";
import { updateinvoiceFormStates } from "../store/actions";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import moment from "moment";
import { COLUMN_DETAILS, HEADER_LIST, HEADER_LIST_ACTUAL } from "./split/config";
var _ = require('lodash');

function JoinFileScreen() {
    const inputRef = useRef(null);
    const drop = useRef(null);
    const [stateValues, setStateValues] = useState({});
    const [load, setLoad] = useState(false);

    const fieldContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
    };
    const ButtonStyle = {
        marginTop: 1,
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#3c82ea",
        borderRadius: "15px",
        px: 3,
    };
    const iconStyle = {
        color: "#81b3fd",
    };
    const handelclearfile = (key) => {
        setLoad(false);
        drop.current.value = "";
        inputRef.current.value = "";
        const t = { ...stateValues }
        delete t[key]
        setStateValues(t);
    };
    const showErrorMsg = (msg) => {
        setIsError(msg);
        setTimeout(() => setIsError(false), 3000)
    }
    const convertExcelToJson = async (file1, callBack) => {
        setLoad(true)
        let final = [];
        let error = [];
        for (let x in file1) {
            const file = file1[x];
            const asyncFun = async () => {
                try {
                    const promise = await new Promise((resolve, reject) => {
                        const fileReader = new FileReader();
                        fileReader.readAsArrayBuffer(file);
                        fileReader.onload = (e) => {
                            const bufferArray = e.target.result;
                            const wb = XLSX.read(bufferArray, { type: 'buffer' });
                            // const wsName = wb.SheetNames[0];
                            const wsName = wb.SheetNames[0];
                            const ws = wb.Sheets[wsName];
                            const data = XLSX.utils.sheet_to_json(ws, { defval: null })
                            console.log(data, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                            resolve(data);
                            fileReader.onerror = (e) => {
                                reject(e)
                            }
                        }
                    }).then(res => {
                        if (res?.length) {
                            // const filtered = res?.filter(item => item. )
                            const t = Object.keys(res[0]).filter(i => !i.includes('__EMPTY'));
                            const columnDa = COLUMN_DETAILS.getcolumnNames();
                            if (columnDa?.length !== t.length) {
                                // if (HEADER_LIST.length !== t.length && t.length !== 21 && t.length !== 22) {
                                return error.push({ name: file.name, error: ['Column Mismatch Error'] });

                            }
                            const te = [...columnDa, COLUMN_DETAILS.columnData.amount_1.validationName]
                            const matches = t.filter((element) => te?.includes(element));
                            if (t.length === matches.length && t.length === COLUMN_DETAILS.getcolumnNames()?.length) final.push(...res);
                            else {
                                return error.push({ name: file.name, error: ['Column Mismatch Error'] });
                            }

                        }
                        // setConvertedFile((prev) => {
                        //     return [...prev, ...res]
                        // })
                    }).catch((err) => {
                        console.log(err, 'szdxfcgvhbjn');
                    })
                } catch (error) {
                    console.log(error, 'szdxfcgvhbjn');
                    setLoad(false);
                }
            }
            await asyncFun();

        }
        callBack && callBack(final, error);
        setLoad(false);

    }
    const handleSubmit = async () => {
        if (Object.keys(stateValues).length) {
            await convertExcelToJson(stateValues, async (convertedFile, errorList) => {
                if (errorList.length) {
                    setStateValues({});
                    drop.current.value = "";
                    inputRef.current.value = "";
                    return setErrorList(errorList)
                }
                else {
                    // const sortedConvertedFile = convertedFile?.reverse().sort((a, b) => a.TYPE > b.TYPE ? 1 : -1);
                    let DNo = 0;
                    const addedNewSNo = convertedFile?.map((data, index) => {
                        const t = { ...data };
                        t['S.No'] = index + 1;
                        if (t['File Name']) {
                            t['D.S.No'] = DNo + 1;
                            DNo += 1
                        }
                        return t
                    })
                    const ws = XLSX.utils.json_to_sheet([...addedNewSNo], { origin: 'A2', skipHeader: true });
                    XLSX.utils.sheet_add_aoa(ws, [COLUMN_DETAILS.getcolumnNames()], { origin: 'A1' });
                    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                    const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
                    const data = await new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, 'MergeFile');
                    setStateValues({});
                    drop.current.value = "";
                    inputRef.current.value = "";
                }
                setStateValues({});
                drop.current.value = "";
                inputRef.current.value = "";

            })

        } else showErrorMsg('Kindly Select File');


    }
    const hangelFile = (e, sort) => {
        try {
            setLoad(true);
            const file = { ...e.target?.files };
            let fileObj = {}
            Object.values(file).map((x) => {
                fileObj[x.name] = x
            })
            if (sort) {
                const t = {}
                const backObj = { ...stateValues, ...fileObj }
                const sorted = Object.keys(backObj).sort((a, b) => +a.split('_')[0] > +b.split('_')[0] ? 1 : -1).forEach(i => t[i] = backObj[i]);
                setStateValues({ ...t })
            }
            else setStateValues(prev => { return { ...prev, ...fileObj } })
            setLoad(false);
            setIsError(false);
        } catch (error) {
            setLoad(false);
            setIsError(false);
        }

    };
    const [isError, setIsError] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const handelSubmit = async () => {
        console.log(stateValues, 'sdxfgchvjbkn');
    }
    React.useEffect(() => {
        drop.current.addEventListener("dragover", handleDragOver);
        drop.current.addEventListener("drop", handleDrop);
        return () => {
            drop?.current?.removeEventListener("dragover", handleDragOver);
            drop?.current?.removeEventListener("drop", handleDrop);
        };
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '12345');
            hangelFile({ target: { files: files } })
            // setfirstkey1(files[0]);

        }
    };
    return (
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
            <Box sx={{ pt: 2 }}>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Lato",
                        fontSize: "1.5rem",
                        p: 1,
                    }}
                >
                    Combine File
                </Typography>
                <Card
                    sx={{ p: 4, backgroundColor: "#f0fefc", border: "1px solid #3c82ea" }}
                >
                    <Box ref={drop} sx={fieldContainer}>
                        {" "}
                        {/* <Typography sx={labelStyle}>Key1</Typography> */}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="file"
                                accept=".xlsx"
                                id="select-image"
                                style={{ display: "none" }}
                                ref={inputRef}
                                onChange={(e) => hangelFile(e, true)}
                                multiple
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>
                                {
                                    Object.keys(stateValues).map((file) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Lato",
                                                    textAlign: "justify",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {file}
                                            </Typography>
                                            <DeleteIcon
                                                sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                                onClick={() => {
                                                    handelclearfile(file);
                                                }}
                                            />
                                        </Box>
                                    ))
                                }

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={fieldContainer}>
                        {/* <Typography sx={labelStyle}></Typography> */}
                        {load ? <Button
                            variant="contained"
                            size="small"
                            component="span"
                            onClick={() => handelSubmit()}
                            sx={{
                                marginTop: 1,
                                textTransform: "capitalize",
                                backgroundColor: "#3c82ea",
                                fontFamily: "Lato",
                                borderRadius: "15px",
                                px: 8,
                                py: 1.5,
                            }}
                        >
                            <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} />
                        </Button>
                            :
                            <Button
                                variant="contained"
                                size="small"
                                component="span"
                                onClick={() => handleSubmit()}
                                sx={{
                                    marginTop: 1,
                                    textTransform: "capitalize",
                                    backgroundColor: "#3c82ea",
                                    fontFamily: "Lato",
                                    borderRadius: "15px",
                                    px: 8,
                                    py: 1.5,
                                }}
                            >
                                Merge
                            </Button>}
                    </Box>

                    {errorList.length ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            <Alert severity="error">
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <CloseIcon
                                        sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                        onClick={() => {
                                            setErrorList([]);
                                        }}
                                    />
                                </Box>
                                <Typography>Column Mismatch Error</Typography>
                                {
                                    errorList.map((item) => (
                                        <Accordion sx={{ background: 'rgb(253, 237, 237)' }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography fontSize={13}>{item.name}</Typography>
                                            </AccordionSummary>
                                            {
                                                item.error?.map((err) => (
                                                    <AccordionDetails>
                                                        <Typography ml={2} fontSize={12}>
                                                            *{err}
                                                        </Typography>
                                                    </AccordionDetails>
                                                ))
                                            }

                                        </Accordion>
                                    ))
                                }
                            </Alert>
                        </Box> : null}
                </Card>
            </Box>
        </Box>
    );
}

export default JoinFileScreen;
