import { createSlice } from "@reduxjs/toolkit";

export const common = createSlice({
  name: "common",
  initialState: {
    multipleCopy: [],
    isLogin:false,
  },
  reducers: {
    updateCommonReducer: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { updateCommonReducer } = common.actions;

export default common.reducer;
