import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateCommonStates, updateLoginStates } from "../store/actions";
import { showErrorToast } from "../utils/functions";
import { validationMessages } from "../utils/validator";
import PersonIcon from "@mui/icons-material/Person";
import "./loginScreen.css";
import logo from "../asstes/images/logo.png";
import box from "../asstes/images/login/box.png";
import user from "../asstes/images/login/user.png";
import pass from "../asstes/images/login/password.png";
import { Login } from "../service/loginService";
const LoginScreen = () => {
  const { users, count } = useSelector((state) => state.login);
  const [stateValues, setStateValues] = useState({
    userName: "",
    password: "",
  });
  const [isValidate, setIsValidate] = useState(false);
  const loginSubmit = async () => {
    // axios.post("http://192.168.1.118:8080/api/auth/login", stateValues);
    // setIsValidate(true);
    // if ((stateValues.userName === "ouser1" || stateValues.userName === "ouser2") && (stateValues.password === "5MeOe9R0*$5J" || stateValues.password === "Qma%ZR09b5ef")) {
    if ((stateValues.userName === "ouser1" && stateValues.password === "5MeOe9R0*$5J") || ( stateValues.userName === "ouser2" && stateValues.password === "Qma%ZR09b5ef")) {
      updateCommonStates('isLogin', true)
    } else showErrorToast("Username or password incorrect");
  };
  return (
    <Box className="d-flex justify-center align-items-center flex-1">
      {/* <Card className="cu-card p-5">
        <CardContent> */}
      <Box
        sx={{
          display: "flex",
          height: 400,
        }}
      >
        <Grid container>
          <Grid sx={{ borderRight: "4px solid #d9d4d4", pr: 6 }}>
            <Box
              sx={{
                pt: 18,
                pr: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <img src={logo} style={{ height: 15 }} alt="logo" />
              </Box>
              <Box sx={{ p: 1 }}>
                <img src={box} style={{ height: 40 }} alt="logo" />
              </Box>

              {/* <img src={logo} style={{ height: 100 }} alt="logo" /> */}
            </Box>
          </Grid>
          <Grid sx={{ pl: 7 }}>
            <Box sx={{ pt: 10 }}>
              <Box sx={{ pb: 3 }}>
                {" "}
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                  }}
                >
                  Welcome Back!
                </Typography>
                <Typography sx={{ fontSize: "12px", fontFamily: "Lato" }}>
                  Login to continue
                </Typography>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Paper
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <img src={user} style={{ height: 20 }} alt="logo" />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="User Name"
                    inputProps={{ "aria-label": "User Name" }}
                    value={stateValues.userName}
                    onChange={(e) =>
                      setStateValues({
                        ...stateValues,
                        userName: e.target.value,
                      })
                    }
                  />
                </Paper>
              </Box>
              <Box sx={{ pt: 3 }}>
                {" "}
                <Paper
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <img src={pass} style={{ height: 20 }} alt="logo" />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Password"
                    inputProps={{ "aria-label": "Password" }}
                    value={stateValues.password}
                    type="password"
                    onChange={(e) =>
                      setStateValues({
                        ...stateValues,
                        password: e.target.value,
                      })
                    }
                  />
                </Paper>
              </Box>
              <Box sx={{ pt: 4, borderRadius: "10px" }}>
                <Button
                  className="login-form-items"
                  onClick={loginSubmit}
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Box className="login-form-items">
              <TextField
                fullWidth
                error={isValidate && !stateValues.empId}
                id="standard-error"
                label="Emp.Id"
                variant="outlined"
                size="small"
                helperText={
                  isValidate && !stateValues.empId
                    ? validationMessages.required
                    : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                value={stateValues.empId}
                onChange={(e) =>
                  setStateValues({ ...stateValues, empId: e.target.value })
                }
              />
            </Box>
            <Box className="login-form-items">
              <TextField
                type="number"
                fullWidth
                error={isValidate && !count}
                id="standard-error"
                label="Count"
                variant="outlined"
                size="small"
                helperText={
                  isValidate && !count ? validationMessages.required : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                value={count}
                onChange={(e) => updateLoginStates("count", e.target.value)}
              />
            </Box> */}
        {/* <Box className="login-form-items">
              <Button
                className="login-form-items"
                onClick={loginSubmit}
                variant="contained"
              >
                Login
              </Button>
            </Box> */}
      </Box>
      {/* </CardContent>
      </Card> */}
    </Box>
  );
};

export default LoginScreen;
