import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./invoiceForm.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { validationMessages } from "../../utils/validator";
import moment from "moment";
import FormDataGrid from "./formDataGrid";
import { updateinvoiceFormStates } from "../../store/actions";
import { checkValidations, dateFormatValidator } from "../../utils/functions";
import CustomizedDialogs from "../dialog";
import { regExpConditions } from "../../utils";
import CustomFormLable from "./formLable";
import search from "../../asstes/images/search1.png";
import {
  invoiceFormDropdowns,
  invoiceFormFieldsName,
} from "../../constants/values";
import NavBar from "../appBar";
import { flexbox, keyframes } from "@mui/system";
import { STYLES } from "../../constants/styles";
import { GetDocuments, UpdateDocuments, UpdateJobStatus } from "../../service/jobService";
import { useLocation } from "react-router-dom";
import { TryRounded } from "@mui/icons-material";
import store from "../../store/store";

const XS = 12;
const MD = 6;
const LG = 3;
const schema = yup.object().shape({
  // bFolder: yup.string(),
  // imagePath: yup.string(),
  pdfName: yup
    .string()
    .required(validationMessages.required)
    .matches(
      regExpConditions.includePdfExtensionAtEnd,
      validationMessages.invoiceForm.addPdfExtn
    ),
  issueDate: yup
    .string()
    .matches(
      regExpConditions.dateFormatUsa,
      validationMessages.invoiceForm.dateFormat
    )
    .nullable(),

  dueDate: yup
    .string()
    .matches(
      regExpConditions.dateFormatUsa,
      validationMessages.invoiceForm.dateFormat
    )
    .nullable(),

  amount1: yup.number().nullable(),
  invoiceNo: yup.string(),
  terms: yup.string(),
  poNo: yup.string().matches(/^(?=.*\d).*$|^$/, {
    //original matcher
    message: validationMessages.invoiceForm.alphaNum,
  }),
  // .matches(regExpConditions.AlphaNumeric,validationMessages.invoiceForm.alphaNum),
  amount2: yup.number().nullable(),
  memo: yup.string().nullable(),
  fileName: yup
    .string()
    .required(validationMessages.required)
    .matches(
      regExpConditions.includePdfExtensionAtEnd,
      validationMessages.invoiceForm.addPdfExtn
    ),
  name: yup.string(),
  email: yup.string().email(),
  phone: yup.string(),
  // .matches(/^[0-9]+$/, "Please enter valid mobileNumber number")
  taxId: yup.string(),
  customerAccount: yup.string(),
  comment: yup.string().nullable(),
  remarks: yup.string(),

  // Ramount: yup.array(),
  // Rmemo: yup.array(),
  // Rquantity: yup.array(),
  // Rcost: yup.array(),
});
const InvoiceFormComp = (props) => {
  const { pathname } = useLocation();
  const pathMatch = (pathname === '/from');
  const theme = useTheme();
  const { tableData, currentPosition, compareTableData } = useSelector((state) => state.invoice);
  const { multipleCopy } = useSelector((state) => state.common);
  const { navBarOpen } = useSelector((state) => state.normal);
  const { empId } = useSelector((state) => state.login);
  const { selectedJob } = useSelector((state) => state.job);
  const VARIANT = "outlined";
  const SIZE = "small";
  const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.only("lg"));
  const [count, setCount] = useState(1);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const no = matchesLg ? 4 : matchesMd ? 2 : matchesSm ? 1 : 1;
    setCount(no);
  }, [matchesSm, matchesMd, matchesLg]);
  useEffect(() => {
    const getdata = async () => {
      await GetDocuments(selectedJob._id);
    };
    (pathMatch && tableData?.length === 0) && getdata();
    return () => {
      // console.log('component will unmount');
    }
  }, [pathMatch]);
  useEffect(() => {
    // console.log(pathname !== '/compare/doc', 'firstRenderfirstRenderfirstRenderfirstRendercccccccccc');

    return () => {
      // if (pathname !== '/compare/doc') {
        const fr = store.getState().invoice.firstRender
        console.log(fr, 'firstRenderfirstRenderfirstRenderfirstRender');
        updateinvoiceFormStates('firstRender', false)
        if (!fr) {
          // updateinvoiceFormStates('tableData', []);
          // updateinvoiceFormStates('compareTableData', [])
          resetValues();
          updateinvoiceFormStates('currentPosition', 0)
          updateinvoiceFormStates('firstRender', true)
        }


      // }
    }


  }, [])
  // dialog
  const [open, setOpen] = React.useState(false);
  const [selectBatch, setSelectBatch] = React.useState(null);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [addFieldCount, setAddFieldCount] = useState(1);
  const [addFieldCount2, setAddFieldCount2] = useState(1);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSearch = () => {
    setSearchOpen(false);
  };
  // dialog
  const getBatchNo = () => {
    return empId + moment().unix();
  };
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors, touchedFields },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      pdfName: "",
      issueDate: null,
      dueDate: null,
      amount1: null,
      invoiceNo: "",
      terms: "",
      poNo: "",
      amount2: null,
      memo: null,
      fileName: "",
      name: "",
      email: "",
      phone: "",
      taxId: "",
      customerAccount: "",
      comment: null,
      remarks: "",
      Ramount: [],
      Rmemo: [],
      Rquantity: [],
      Rcost: [],
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      setLoad(true);
      console.log(data, "datadatadata");
      const t = [...tableData];
      const x = data;
      if (x) x.id = moment().unix();
      await UpdateDocuments(data, data._id, (val) => setValue("status", val?.status)).then(() => setLoad(false));
    } catch (error) {
      setLoad(false)
    }

    // currentPosition !== null ? (t[currentPosition] = x) : t.push(x);
    // updateinvoiceFormStates("tableData", t);
    // if (currentPosition === null) {
    // reset();
    // resetValues();
    // if (currentPosition !== null) {
    //   updateinvoiceFormStates("currentPosition", null);
    // }
  };
  const setFormDataValue = (index) => {
    if (tableData?.length) {
      const val = { ...tableData[index] };
      console.log(val, val.Ramount?.length, "val.batch1224234");
      setValue("pdfName", val.pdfName);
      setValue("issueDate", val.issueDate);
      setValue("dueDate", val.dueDate);
      setValue("amount1", val.amount1);
      setValue("invoiceNo", val.invoiceNo);
      setValue("terms", val.terms);
      setValue("poNo", val.poNo);
      setValue("amount2", val.amount2);
      setValue("memo", val.memo);
      setValue("fileName", val.fileName);
      setValue("name", val.name);
      setValue("email", val.email);
      setValue("phone", val.phone);
      setValue("taxId", val.taxId);
      setValue("customerAccount", val.customerAccount);
      setValue("comment", val.comment);
      setValue("remarks", val.remarks);
      setValue("_id", val._id);
      setValue("status", val.status);
      setAddFieldCount(val.Ramount?.length || 1);
      setAddFieldCount2(1);
      // setAddFieldCount2(val.Ramount?.length || 1);
      setValue("Ramount", val.Ramount);
      setValue("Rmemo", val.Rmemo);
      setValue("Rquantity", val.Rquantity);
      setValue("Rcost", val.Rcost);
      costValidation();
    }
  };
  const onPrev = () => {
    resetValues();
    updateinvoiceFormStates("currentPosition", tableData?.length - 1);
    if (currentPosition === null && tableData?.length)
      updateinvoiceFormStates("currentPosition", tableData?.length - 1);
    else {
      currentPosition > 0 &&
        updateinvoiceFormStates("currentPosition", currentPosition - 1);
    }
  };
  const onNext = () => {
    resetValues();
    if (currentPosition < tableData?.length - 1 && currentPosition !== null) updateinvoiceFormStates("currentPosition", currentPosition + 1);

    // else if (currentPosition === tableData?.length - 1) {
    //   updateinvoiceFormStates("currentPosition", null);
    //   reset();
    //   setAddFieldCount(1);
    //   setAddFieldCount2(1);
    // }
  };
  const [totalError, setTotalError] = useState([]);
  const resetValues = () => {
    setTotalError([]);
    setAddFieldCount(1);
    setAddFieldCount2(1);
    reset()
  };
  const costValidation = () => {
    try {
      const tempData = [...totalError];
      for (let item = 0; item <= addFieldCount - 1; item++) {
        const amount = getValues("Ramount")[item];
        const qty = getValues("Rquantity")[item];
        const cost = getValues("Rcost")[item];
        if (amount && qty && cost) {
          if (Number(amount) !== Number(qty) * Number(cost)) {
            !tempData.includes(item) && tempData.push(item);
          } else if (tempData.includes(item)) {
            const index = tempData.indexOf(item);
            tempData.splice(index, 1);
          }
        } else if (tempData.includes(item)) {
          const index = tempData.indexOf(item);
          tempData.splice(index, 1);
        }
      }
      console.log(tempData, "tempData");
      setTotalError(tempData);
    } catch (error) { }
  };
  useEffect(() => {
    if (currentPosition !== null) setFormDataValue(currentPosition);
    else {
      reset();
    }
  }, [currentPosition, tableData]);
  const textFieldRefs = useRef([]);
  const onKeyDownHandler = (e, setValue) => {
    console.log(e, "eee");
    if (e.ctrlKey && e.shiftKey) {
      const isFind = multipleCopy.find((item) => item.keyCode === e.keyCode);
      isFind && setValue({ target: { value: isFind.value } });
    }
    // if (e.keyCode === 49 && e.ctrlKey && e.shiftKey) setValue({target:{value:ctrl1}})
    // if (e.keyCode === 50 && e.ctrlKey && e.shiftKey) setValue({target:{value:ctrl2}})
    // if (e.keyCode === 51 && e.ctrlKey && e.shiftKey) setValue({target:{value:ctrl3}})
    // if (e.keyCode === 52 && e.ctrlKey && e.shiftKey) setValue({target:{value:ctrl4}})
    // if (e.keyCode === 53 && e.ctrlKey && e.shiftKey) setValue({target:{value:ctrl5}})
  };
  const handleKeyDown = (event) => {
    const { keyCode } = event;
    // Arrow up
    if (keyCode === 38 && event.altKey) {
      event.preventDefault();
      const currentIndex = textFieldRefs.current.findIndex(
        (ref) => ref.id === document.activeElement?.id
      );
      setCount((count) => {
        const dynamicField =
          currentIndex >= 17 && currentIndex <= 20 ? 16 : currentIndex > 16 ? currentIndex - 4 : currentIndex - count;

        const nextIndex =
          currentIndex > 0
            ? dynamicField
            : textFieldRefs.current.length - count;
        nextIndex >= 0 && textFieldRefs.current[nextIndex] && textFieldRefs.current[nextIndex].focus();
        return count;
      });
    }

    // Arrow down
    if (keyCode === 40 && event.altKey) {
      event.preventDefault();
      const currentIndex = textFieldRefs.current.findIndex(
        (ref) => ref.id === document.activeElement?.id
      );
      console.log(currentIndex, "currentIndexcurrentIndexcurrentIndex");
      setCount((count) => {
        const nextIndex =
          currentIndex < textFieldRefs.current.length - count
            ? (currentIndex >=15 && currentIndex <=16 )? 17:  currentIndex > 16 ? currentIndex + 4 : currentIndex + count
            : 0;
            nextIndex >= 0 && textFieldRefs.current[nextIndex] && textFieldRefs.current[nextIndex].focus();
        return count;
      });
    }
    if (keyCode === 37 && event.altKey) {
      event.preventDefault();
      const currentIndex = textFieldRefs.current.findIndex(
        (ref) => ref.id === document.activeElement?.id
      );
      const no = 1;
      const nextIndex =
        currentIndex > 0
          ? currentIndex - no
          : textFieldRefs.current.length - no;

      nextIndex >= 0 && textFieldRefs.current[nextIndex] && textFieldRefs.current[nextIndex].focus();
    }
    if (keyCode === 39 && event.altKey) {
      event.preventDefault();
      const no = 1;
      const currentIndex = textFieldRefs.current.findIndex(
        (ref) => ref.id === document.activeElement?.id
      );
      const nextIndex =
        currentIndex < textFieldRefs.current.length - no
          ? currentIndex + no
          : 0;
          nextIndex >= 0 && textFieldRefs.current[nextIndex] && textFieldRefs.current[nextIndex].focus();
    }
  };
  useEffect(() => {
    // Initialize the array of text field refs with the DOM nodes
    textFieldRefs.current = textFieldRefs.current
      .slice(0, 3)
      .map((_, i) => textFieldRefs.current[i] || React.createRef());

    // Add event listener to the document object for arrow keys
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const getErrorState = (stateName, error, msg, index) => {
    try {
      if (pathMatch) return msg ? error?.message || "" : error
      else if (index || index === 0) {
        if (getValues(stateName)[index] !== compareTableData[currentPosition][stateName][index]) {
          return msg ? `key2 value is ${compareTableData[currentPosition][stateName][index] || 'NA'}` : true;
        }
      }
      else if (getValues(stateName) !== compareTableData[currentPosition][stateName]) {
        return msg ? `key2 value is ${compareTableData[currentPosition][stateName] || 'NA'}` : true;
      }
      else return msg ? '' : false;
    } catch (error) {
      return msg ? '' : false;
    }


  }
  return (
    <Box
      sx={{
        marginLeft: navBarOpen ? "240px" : "0px",
      }}
    >
      <Box>
        <NavBar selectedJob={selectedJob} />
      </Box>
      <Divider />
      <Box className="form-component-container">
        <Card
          className="form-fields-container cu-card"
          component="form"
          noValidate
          onSubmit={(e) => e.preventDefault()}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="pdfName"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[0] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('pdfName', errors.pdfName)}
                    helperText={getErrorState('pdfName', errors.pdfName, true)}
                    fullWidth
                    id="pdfName"
                    label={
                      <CustomFormLable
                        no="1"
                        name={invoiceFormFieldsName.pdfName}
                      />
                    }
                    name="pdfName"
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    onBlur={() => getValues("pdfName") && trigger("pdfName")}
                    value={getValues("pdfName")}
                    onChange={(e) => {
                      // field.onChange(e.target.value);
                      const t = () => {
                        setValue("pdfName", e.target.value);
                        setValue("fileName", e.target.value);
                      };
                      if (e) return t();
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        const t = () => {
                          setValue("pdfName", e.target.value);
                          setValue("fileName", e.target.value);
                        };
                        if (e) return t();
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="issueDate"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[1] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('issueDate', errors.issueDate)}
                    helperText={getErrorState('issueDate', errors.issueDate, true)}
                    fullWidth
                    id="issueDate"
                    label={
                      <CustomFormLable
                        no="2"
                        name={invoiceFormFieldsName.issueDate}
                      />
                    }
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("issueDate") || ""}
                    onBlur={() =>
                      getValues("issueDate") && trigger("issueDate")
                    }
                    placeholder="yyyy-mm-dd"
                    onChange={(e) => {
                      // field.onChange(e.target.value);
                      if (e.target.value === "")
                        return setValue("issueDate", null);
                      const val = e.target.value;
                      const t = val
                        ? dateFormatValidator(val, getValues("issueDate"))
                        : "";
                      if (t || t === "") return setValue("issueDate", t);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e.target.value === "")
                          return setValue("issueDate", null);
                        const val = e.target.value;
                        const t = val
                          ? dateFormatValidator(val, getValues("issueDate"))
                          : "";
                        if (t || t === "") return setValue("issueDate", t);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="dueDate"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[2] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('dueDate', errors.dueDate)}
                    helperText={getErrorState('dueDate', errors.dueDate, true)}
                    fullWidth
                    id="dueDate"
                    label={
                      <CustomFormLable
                        no="3"
                        name={invoiceFormFieldsName.dueDate}
                      />
                    }
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("dueDate") || ""}
                    onBlur={() => getValues("dueDate") && trigger("dueDate")}
                    placeholder="yyyy-mm-dd"
                    onChange={(e) => {
                      // field.onChange(e.target.value);
                      if (e.target.value === "")
                        return setValue("dueDate", null);
                      const val = e.target.value;
                      const t = val
                        ? dateFormatValidator(val, getValues("dueDate"))
                        : "";
                      if (t || t === "") return setValue("dueDate", t);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e.target.value === "")
                          return setValue("dueDate", null);
                        const val = e.target.value;
                        const t = val
                          ? dateFormatValidator(val, getValues("dueDate"))
                          : "";
                        if (t || t === "") return setValue("dueDate", t);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="amount1"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[3] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('amount1', errors.amount1)}
                    helperText={getErrorState('amount1', errors.amount1, true)}
                    fullWidth
                    id="amount1"
                    label={
                      <CustomFormLable
                        no="4"
                        name={invoiceFormFieldsName.amount1}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    onBlur={() => {
                      if (getValues("amount1")) {
                        const val = Number(getValues("amount1"));
                        if (getValues("amount1") || getValues("amount1") === 0)
                          setValue("amount1", val?.toFixed(2));
                      }
                    }}
                    value={getValues("amount1") || ""}
                    onChange={(e) => {
                      // field.onChange(e.target.value);

                      if (e.target.value === "")
                        return setValue("amount1", null);
                      const t = checkValidations.numbersWithDecimal(
                        e.target.value
                      );
                      if (t) return setValue("amount1", t);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e.target.value === "")
                          return setValue("amount1", null);
                        const t = checkValidations.numbersWithDecimal(
                          e.target.value
                        );
                        if (t) return setValue("amount1", t);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="invoiceNo"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[4] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('invoiceNo', errors.invoiceNo)}
                    helperText={getErrorState('invoiceNo', errors.invoiceNo, true)}
                    fullWidth
                    id="invoiceNo"
                    label={
                      <CustomFormLable
                        no="5"
                        name={invoiceFormFieldsName.invoiceNo}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("invoiceNo")}
                    onChange={(e) => {
                      if (e) return setValue("invoiceNo", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) return setValue("invoiceNo", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="terms"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[5] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('terms', errors.terms)}
                    helperText={getErrorState('terms', errors.terms, true)}
                    fullWidth
                    id="terms"
                    label={
                      <CustomFormLable
                        no="6"
                        name={invoiceFormFieldsName.terms}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("terms")}
                    onChange={(e) => {
                      if (e) return setValue("terms", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) return setValue("terms", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="poNo"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[6] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('poNo', errors.poNo)}
                    helperText={getErrorState('poNo', errors.poNo, true)}
                    fullWidth
                    id="poNo"
                    label={
                      <CustomFormLable
                        no="7"
                        name={invoiceFormFieldsName.poNo}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("poNo")}
                    onBlur={() => getValues("poNo") && trigger("poNo")}
                    onChange={(e) => {
                      if (e) {
                        setValue("poNo", e.target.value);
                        return;
                      }
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) {
                          setValue("poNo", e.target.value);
                          return;
                        }
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="amount2"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[7] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('amount2', errors.amount2)}
                    helperText={getErrorState('amount2', errors.amount2, true)}
                    fullWidth
                    id="amount2"
                    label={
                      <CustomFormLable
                        no="8"
                        name={invoiceFormFieldsName.amount2}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    onBlur={() => {
                      if (getValues("amount2")) {
                        const val = Number(getValues("amount2"));
                        if (getValues("amount2") || getValues("amount2") === 0)
                          setValue("amount2", val?.toFixed(2));
                      }
                    }}
                    value={getValues("amount2") || ""}
                    onChange={(e) => {
                      // field.onChange(e.target.value);
                      if (e.target.value === "") return setValue("amount2", "");
                      const t = checkValidations.numbersWithDecimal(
                        e.target.value
                      );
                      if (t) return setValue("amount2", t);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e.target.value === "")
                          return setValue("amount2", "");
                        const t = checkValidations.numbersWithDecimal(
                          e.target.value
                        );
                        if (t) return setValue("amount2", t);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="memo"
                render={({ field }) => (
                  <FormControl fullWidth>
                    {/* <InputLabel style={{ fontWeight: 'bold' }} shrink id="demo-simple-select-label"><CustomFormLable no="9" name={invoiceFormFieldsName.memo} /></InputLabel> */}
                    {/* <Select
                                        inputRef={(e) => textFieldRefs.current[8] = e}
                                        labelId="demo-simple-select-label"
                                        id="memo"
                                        name="memo"
                                        label={<CustomFormLable no="9" name="Memo" />} 
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        notched
                                        onChange={async (e) => {
                                            if (e) return setValue("memo", e.target.value);
                                        }}
                                        value={getValues("memo")}
                                        helperText={errors?.memo?.message}
                                        error={errors?.memo}
                                    >
                                        <MenuItem style={{ fontSize: 13, paddingBottom: 2, paddingTop: 0 }} key={'01001M'} value={''}>Select</MenuItem>
                                        <MenuItem style={{ fontSize: 13, paddingBottom: 2, paddingTop: 0 }} key={'0101M'} value={'Tax'}>Tax</MenuItem>
                                        <MenuItem style={{ fontSize: 13, paddingTop: 5, paddingBottom: 0, }} key={'0102M'} value={'Freight'}>Freight</MenuItem>
                                    </Select> */}
                    <Autocomplete
                      fullWidth
                      options={invoiceFormDropdowns.memo}
                      getOptionLabel={(option) => option.label}
                      id="memo1"
                      clearOnEscape
                      className="invoice-form-fields"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          inputRef={(e) => (textFieldRefs.current[8] = e)}
                          id="memo"
                          error={getErrorState('memo', errors.memo)}
                          helperText={getErrorState('memo', errors.memo, true)}
                          {...params}
                          label={
                            <CustomFormLable
                              no="9"
                              name={invoiceFormFieldsName.memo}
                            />
                          }
                          autoFocus
                          variant={VARIANT}
                          size={SIZE}
                          className="invoice-form-fields"
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      // value={(currentPosition !== null && tableData?.length) ? tableData[currentPosition]?.pdfName === selectBatch?.pdfName ? selectBatch : null : null}
                      onChange={(e, i) => {
                        console.log(
                          getValues("memo"),
                          'getValues("memo")getValues("memo")'
                        );
                        i ? setValue("memo", i.value) : setValue("memo", null);
                      }}
                      value={
                        !getValues("memo")
                          ? null
                          : invoiceFormDropdowns.memo.find(
                            (i) => i.value === getValues("memo")
                          )
                      }
                      disableCloseOnSelect={false}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="fileName"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[9] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('fileName', errors.fileName)}
                    helperText={getErrorState('fileName', errors.fileName, true)}
                    fullWidth
                    id="fileName"
                    label={
                      <CustomFormLable
                        no="10"
                        name={invoiceFormFieldsName.fileName}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("fileName")}
                    onBlur={() => getValues("fileName") && trigger("fileName")}
                    onChange={(e) => {
                      const t = () => {
                        setValue("pdfName", e.target.value);
                        setValue("fileName", e.target.value);
                      };
                      if (e) return t();
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        const t = () => {
                          setValue("pdfName", e.target.value);
                          setValue("fileName", e.target.value);
                        };
                        if (e) return t();
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[10] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('name', errors.name)}
                    helperText={getErrorState('name', errors.name, true)}
                    fullWidth
                    id="name"
                    label={
                      <CustomFormLable
                        no="11"
                        name={invoiceFormFieldsName.name}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("name")}
                    onChange={(e) => {
                      if (e)
                        return setValue(
                          "name",
                          e.target.value?.replace(/[0-9]/g, "")
                        );
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e)
                          return setValue(
                            "name",
                            e.target.value?.replace(/[0-9]/g, "")
                          );
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[11] = e)}
                    autoComplete="off"
                    aria-multiline
                    error={getErrorState('email', errors.email)}
                    helperText={getErrorState('email', errors.email, true)}
                    fullWidth
                    id="email"
                    label={
                      <CustomFormLable
                        no="12"
                        name={invoiceFormFieldsName.email}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("email")}
                    onBlur={() => getValues("email") && trigger("email")}
                    onChange={(e) => {
                      if (e) return setValue("email", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) return setValue("email", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[12] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('phone', errors.phone)}
                    helperText={getErrorState('phone', errors.phone, true)}
                    fullWidth
                    id="phone"
                    label={
                      <CustomFormLable
                        no="13"
                        name={invoiceFormFieldsName.phone}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("phone")}
                    onChange={(e) => {
                      // field.onChange(e.target.value);
                      if (e)
                        return setValue(
                          "phone",
                          e.target.value?.replace(/[A-Za-z]/g, "")
                        );
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e)
                          return setValue(
                            "phone",
                            e.target.value?.replace(/[A-Za-z]/g, "")
                          );
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="taxId"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[13] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('taxId', errors.taxId)}
                    helperText={getErrorState('taxId', errors.taxId, true)}
                    fullWidth
                    id="taxId"
                    label={
                      <CustomFormLable
                        no="14"
                        name={invoiceFormFieldsName.taxId}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("taxId")}
                    onChange={(e) => {
                      if (e) return setValue("taxId", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) return setValue("taxId", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="customerAccount"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[14] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('customerAccount', errors.customerAccount)}
                    helperText={getErrorState('customerAccount', errors.customerAccount, true)}
                    fullWidth
                    id="customerAccount"
                    label={
                      <CustomFormLable
                        no="15"
                        name={invoiceFormFieldsName.customerAccount}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("customerAccount")}
                    onChange={(e) => {
                      if (e) return setValue("customerAccount", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e)
                          return setValue("customerAccount", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="comment"
                render={({ field }) => (
                  <FormControl fullWidth>
                    {/* <InputLabel style={{ fontWeight: 'bold' }} shrink id="demo-simple-select-label"><CustomFormLable no="16" name={invoiceFormFieldsName.comment} /></InputLabel> */}
                    {/* <Select
                                        inputRef={(e) => textFieldRefs.current[15] = e}
                                        labelId="demo-simple-select-label"
                                        id="comment"
                                        name="comment"
                                        label={<CustomFormLable no="16" name="Comment" />}
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        notched
                                        onChange={async (e) => {
                                            if (e) return setValue("comment", e.target.value);
                                        }}
                                        value={getValues("comment")}
                                        helperText={errors?.comment?.message}
                                        error={errors?.comment}
                                    >
                                        <MenuItem style={{ fontSize: 13, paddingBottom: 2, paddingTop: 0 }} key={'010001C'} value={''}>Select</MenuItem>
                                        <MenuItem style={{ fontSize: 13, paddingBottom: 2, paddingTop: 0 }} key={'0101C'} value={'File cannot be processed'}>File cannot be processed</MenuItem>
                                        <MenuItem style={{ fontSize: 13, paddingTop: 5, paddingBottom: 0, }} key={'0102C'} value={'File is not an invoice'}>File is not an invoice</MenuItem>
                                    </Select> */}
                    <Autocomplete
                      fullWidth
                      options={invoiceFormDropdowns.comments}
                      getOptionLabel={(option) => option.label}
                      id="comment1"
                      clearOnEscape
                      className="invoice-form-fields"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          inputRef={(e) => (textFieldRefs.current[15] = e)}
                          id="comment"
                          error={getErrorState('comment', errors.comment)}
                          helperText={getErrorState('comment', errors.comment, true)}
                          {...params}
                          label={
                            <CustomFormLable
                              no="16"
                              name={invoiceFormFieldsName.comment}
                            />
                          }
                          autoFocus
                          variant={VARIANT}
                          size={SIZE}
                          className="invoice-form-fields"
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      // value={(currentPosition !== null && tableData?.length) ? tableData[currentPosition]?.pdfName === selectBatch?.pdfName ? selectBatch : null : null}
                      onChange={(e, i) => {
                        i
                          ? setValue("comment", i.value)
                          : setValue("comment", null);
                      }}
                      value={
                        !getValues("comment")
                          ? null
                          : invoiceFormDropdowns.comments.find(
                            (i) => i.value === getValues("comment")
                          )
                      }
                      disableCloseOnSelect={false}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid
              item
              xs={XS}
              md={MD}
              lg={LG}
              width={"100%"}
              className="invoice-form-grid-item"
            >
              <Controller
                control={control}
                name="remarks"
                render={({ field }) => (
                  <TextField
                    inputRef={(e) => (textFieldRefs.current[16] = e)}
                    autoComplete="off"
                    multiline
                    error={getErrorState('remarks', errors.comment)}
                    helperText={getErrorState('remarks', errors.comment, true)}
                    fullWidth
                    id="remarks"
                    label={
                      <CustomFormLable
                        no="17"
                        name={invoiceFormFieldsName.remarks}
                      />
                    }
                    autoFocus
                    variant={VARIANT}
                    size={SIZE}
                    className="invoice-form-fields"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: "bold",
                      },
                    }}
                    value={getValues("remarks")}
                    onChange={(e) => {
                      if (e) return setValue("remarks", e.target.value);
                    }}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, (e) => {
                        if (e) return setValue("remarks", e.target.value);
                      })
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box className="second-row-items">
            <Box className="d-flex justify-end align-items-center mb-1">
              <Button variant="contained" onClick={handleClickOpen}>
                +Add
              </Button>
            </Box>
            {Array.from(Array(Number(addFieldCount) || 1).keys()).map(
              (item) => (
                <Box className="flex-center">
                  <Grid key={item} container spacing={2} className="mb-1">
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      width={"100%"}
                      className="invoice-form-grid-item"
                    >
                      <Controller
                        control={control}
                        name="Ramount"
                        render={({ field }) => (
                          <TextField
                            inputRef={(e) =>
                              (textFieldRefs.current[13 + (item + 1) * 4] = e)
                            }
                            autoComplete="off"
                            multiline
                            // error={totalError.includes(item) || errors.Ramount}
                            // helperText={
                            //   errors.Ramount
                            //     ? errors.Ramount.message
                            //     : totalError.includes(item)
                            //       ? validationMessages.invoiceForm
                            //         .amountTotalNotmatch
                            //       : ""
                            // }
                            error={getErrorState('Ramount', errors.Ramount, false, item) || totalError.includes(item)}
                            helperText={getErrorState('Ramount', errors.Ramount, true, item) || (totalError.includes(item) ? validationMessages.invoiceForm.amountTotalNotmatch : '')}
                            fullWidth
                            id={"Ramount" + item}
                            label={
                              <CustomFormLable
                                no=""
                                name={invoiceFormFieldsName.amount2}
                              />
                            }
                            autoFocus
                            variant={VARIANT}
                            size={SIZE}
                            className="invoice-form-fields"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                fontWeight: "bold",
                              },
                            }}
                            value={getValues("Ramount")[item] || ""}
                            onBlur={() => {
                              const val = getValues("Ramount")[item];
                              if (val) {
                                const value = Number(val);
                                if (value || value === 0) {
                                  // const allData = [...getValues("Ramount")];
                                  // allData[item] = value?.toFixed(2);
                                  // setValue("Ramount", allData);
                                  costValidation();
                                }
                              }
                            }}
                            onChange={(e) => {
                              // field.onChange(e.target.value);
                              if (e.target.value === "") {
                                const allData = [...getValues("Ramount")];
                                allData[item] = "";
                                setValue("Ramount", allData);
                                costValidation();
                              }
                              const t = checkValidations.numbersWithDecimal(
                                e.target.value
                              );
                              if (t) {
                                const allData = [...getValues("Ramount")];
                                allData[item] = t;
                                setValue("Ramount", allData);
                                costValidation();
                              }
                            }}
                            onKeyDown={(event) =>
                              onKeyDownHandler(event, (e) => {
                                if (e.target.value === "") {
                                  const allData = [...getValues("Ramount")];
                                  allData[item] = "";
                                  setValue("Ramount", allData);
                                  costValidation();
                                }
                                const t = checkValidations.numbersWithDecimal(
                                  e.target.value
                                );
                                if (t) {
                                  const allData = [...getValues("Ramount")];
                                  allData[item] = t;
                                  setValue("Ramount", allData);
                                  costValidation();
                                }
                              })
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      width={"100%"}
                      className="invoice-form-grid-item"
                    >
                      <Controller
                        control={control}
                        name="Rmemo"
                        render={({ field }) => (
                          <TextField
                            inputRef={(e) =>
                              (textFieldRefs.current[14 + (item + 1) * 4] = e)
                            }
                            autoComplete="off"
                            multiline

                            error={getErrorState('Rmemo', errors.Rmemo, false, item)}
                            helperText={getErrorState('Rmemo', errors.Rmemo, true, item)}
                            fullWidth
                            id={"Rmemo" + item}
                            label={
                              <CustomFormLable
                                no=""
                                name={invoiceFormFieldsName.memo}
                              />
                            }
                            autoFocus
                            variant={VARIANT}
                            size={SIZE}
                            className="invoice-form-fields"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                fontWeight: "bold",
                              },
                            }}
                            value={getValues("Rmemo")[item] || ""}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                const allData = [...getValues("Rmemo")];
                                allData[item] = "";
                                setValue("Rmemo", allData);
                              }
                              if (e) {
                                const allData = [...getValues("Rmemo")];
                                allData[item] = e.target.value;
                                setValue("Rmemo", allData);
                              }
                            }}
                            onKeyDown={(event) =>
                              onKeyDownHandler(event, (e) => {
                                if (e.target.value === "") {
                                  const allData = [...getValues("Rmemo")];
                                  allData[item] = "";
                                  setValue("Rmemo", allData);
                                }
                                if (e) {
                                  const allData = [...getValues("Rmemo")];
                                  allData[item] = e.target.value;
                                  setValue("Rmemo", allData);
                                }
                              })
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      width={"100%"}
                      className="invoice-form-grid-item"
                    >
                      <Controller
                        control={control}
                        name="Rquantity"
                        render={({ field }) => (
                          <TextField
                            inputRef={(e) =>
                              (textFieldRefs.current[15 + (item + 1) * 4] = e)
                            }
                            autoComplete="off"
                            multiline
                            error={getErrorState('Rquantity', errors.Rquantity, false, item) || totalError.includes(item)}
                            helperText={getErrorState('Rquantity', errors.Rquantity, true, item) || (totalError.includes(item) ? validationMessages.invoiceForm.amountTotalNotmatch : '')}
                            fullWidth
                            id={"Rquantity" + item}
                            label={<CustomFormLable no="" name="Quantity" />}
                            autoFocus
                            variant={VARIANT}
                            size={SIZE}
                            className="invoice-form-fields"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                fontWeight: "bold",
                              },
                            }}
                            // type="number"
                            value={getValues("Rquantity")[item] || ""}

                            onChange={(e) => {
                              if (e.target.value === "") {
                                const allData = [...getValues("Rquantity")];
                                allData[item] = "";
                                setValue("Rquantity", allData);
                                costValidation();
                              }
                              const t = checkValidations.numbersWithDecimal(
                                e.target.value
                              );
                              if (t) {
                                const allData = [...getValues("Rquantity")];
                                allData[item] = t;
                                setValue("Rquantity", allData);
                                costValidation();
                              }
                            }}
                            onKeyDown={(event) =>
                              onKeyDownHandler(event, (e) => {
                                if (e.target.value === "") {
                                  const allData = [...getValues("Rquantity")];
                                  allData[item] = "";
                                  setValue("Rquantity", allData);
                                  costValidation();
                                }
                                const t = checkValidations.numbersWithDecimal(
                                  e.target.value
                                );
                                if (t) {
                                  const allData = [...getValues("Rquantity")];
                                  allData[item] = t;
                                  setValue("Rquantity", allData);
                                  costValidation();
                                }
                              })
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      width={"100%"}
                      className="invoice-form-grid-item"
                    >
                      <Controller
                        control={control}
                        name="Rcost"
                        render={({ field }) => (
                          <TextField
                            inputRef={(e) =>
                              (textFieldRefs.current[16 + (item + 1) * 4] = e)
                            }
                            autoComplete="off"
                            multiline
                            error={getErrorState('Rcost', errors.Rcost, false, item) || totalError.includes(item)}
                            helperText={getErrorState('Rcost', errors.Rcost, true, item) || (totalError.includes(item) ? validationMessages.invoiceForm.amountTotalNotmatch : '')}
                            fullWidth
                            id={"Rcost" + item}
                            label={<CustomFormLable no="" name="Cost" />}
                            autoFocus
                            variant={VARIANT}
                            size={SIZE}
                            className="invoice-form-fields"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                fontWeight: "bold",
                              },
                            }}
                            onBlur={() => {
                              const val = getValues("Rcost")[item];
                              if (val) {
                                const value = Number(val);
                                if (value || value === 0) {
                                  // const allData = [...getValues("Rcost")];
                                  // allData[item] = value?.toFixed(2);
                                  // setValue("Rcost", allData);
                                  costValidation();
                                }
                              }
                            }}
                            value={getValues("Rcost")[item] || ""}
                            onChange={(e) => {
                              // field.onChange(e.target.value);
                              if (e.target.value === "") {
                                const allData = [...getValues("Rcost")];
                                allData[item] = "";
                                setValue("Rcost", allData);
                                costValidation();
                              }
                              const t = checkValidations.numbersWithDecimal(
                                e.target.value
                              );
                              if (t) {
                                const allData = [...getValues("Rcost")];
                                allData[item] = t;
                                setValue("Rcost", allData);
                                costValidation();
                              }
                            }}
                            onKeyDown={(event) =>
                              onKeyDownHandler(event, (e) => {
                                if (e.target.value === "") {
                                  const allData = [...getValues("Rcost")];
                                  allData[item] = "";
                                  setValue("Rcost", allData);
                                  costValidation();
                                }
                                const t = checkValidations.numbersWithDecimal(
                                  e.target.value
                                );
                                if (t) {
                                  const allData = [...getValues("Rcost")];
                                  allData[item] = t;
                                  setValue("Rcost", allData);
                                  costValidation();
                                }
                              })
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/* <Button variant='contained'>X</Button> */}
                </Box>
              )
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              {tableData?.length ? <FormDataGrid data={tableData} /> : null}
              <Button onClick={() => {
                UpdateJobStatus({ status: "COMPLETED" }, selectedJob._id, true).then(res => { })
              }}
                disabled={selectedJob.status === "COMPLETED"}
                sx={{ ml: 2, textTransform: 'capitalize' }} color="success" variant="contained">Complete job</Button>
            </Box>
            <Box className="d-flex justify-end align-items-center">
              <Box
                className="d-flex justify-end p-2 cursor-pointer"
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                <img src={search} style={{ height: 35 }} alt="search" />
              </Box>
              <Box className="d-flex justify-end p-2">
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  type="submit"
                  disabled={tableData?.length === 0 || currentPosition === 0}
                  onClick={onPrev}
                >
                  Back
                </Button>
              </Box>

              <Box className="d-flex justify-end p-2">
                <Button
                  color="warning"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  type="submit"
                  disabled={
                    tableData?.length === 0 ||
                    currentPosition === tableData?.length - 1 ||
                    currentPosition === null
                  }
                  onClick={onNext}
                >
                  Next
                </Button>
              </Box>
              <Box className="d-flex justify-end p-2">
                <Button
                  color="success"
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                >
                  {/* {currentPosition !== null ? "Update" : "Submit"} */}
                  {load ? <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} /> : null}
                  {getValues("status") === "COMPLETED" ? "Update" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <button id="bottom" onClick={() => window.scrollTo(0, 0)}>ssqxwxde</button> */}
        </Card>

        <CustomizedDialogs
          open={open}
          maxWidth={"sm"}
          handleClose={handleClose}
        >
          <Box className="flex-center">
            <Box className="me-1">
              <TextField
                autoComplete="off"
                multiline
                fullWidth
                id="standard-error"
                label="Count"
                type="number"
                autoFocus
                variant={VARIANT}
                size={SIZE}
                className="invoice-form-fields"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: "bold",
                  },
                }}
                value={addFieldCount2}
                onChange={(e) => {
                  if (e && e.target.value >= 0)
                    setAddFieldCount2(e.target.value);
                }}
              />
            </Box>

            <Button
              size="large"
              variant="contained"
              onClick={() => {
                handleClose();
                setAddFieldCount(Number(addFieldCount2) + Number(addFieldCount) || 1);
              }}
            >
              Done
            </Button>
          </Box>
        </CustomizedDialogs>
        <CustomizedDialogs
          open={searchOpen}
          title={"Document Search"}
          handleClose={handleCloseSearch}
        >
          <Box className="flex-center p-1">
            <Paper
              sx={{
                p: "0px 4px",
                display: "flex",
                alignItems: "center",
                width: 300,
                boxShadow: STYLES.boxShadow.white,
                height: 30,
              }}
            >
              <Autocomplete
                fullWidth
                options={tableData}
                getOptionLabel={(option) => option.pdfName}
                id="clear-on-escape"
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    size="small"
                    placeholder="Search"
                    {...params}
                    variant="standard"
                  />
                )}
                style={{ width: 300 }}
                value={
                  currentPosition !== null && tableData?.length
                    ? tableData[currentPosition]?.pdfName ===
                      selectBatch?.pdfName
                      ? selectBatch
                      : null
                    : null
                }
                onChange={(e, i) => {
                  setSelectBatch(i);
                  const index = tableData?.findIndex(
                    (item) => item?.pdfName === i?.pdfName
                  );
                  index >= 0 &&
                    updateinvoiceFormStates("currentPosition", index);
                }}
                disableCloseOnSelect={false}
              />
            </Paper>
          </Box>
        </CustomizedDialogs>
      </Box>
    </Box>
  );
};
export default InvoiceFormComp;
