import {
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    IconButton,
    InputBase,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import file from "../asstes/images/file.png";
import upload from "../asstes/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import FileSaver from "file-saver";
import moment from "moment";
import { COLUMN_DETAILS, EMPLOYEE_NAME_LIST, SPLIT_GRACE_VALUE, TRACKER_COLUMN_DETAILS, TRACKER_STATUS } from "./split/config";
import { cmt, cmt2, columnCheck, COMMENTS_EXCEL, FINAL_COLUMN, MEMO_LIST_EXCEL, SPLIT_COLUMN, SPLIT_TEMPLATE, totalExceedCmt } from "./qch/validation";
var _ = require('lodash');

function FinalSheetScreen() {
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const drop = useRef(null);
    const drop2 = useRef(null);
    const drop3 = useRef(null);
    const [stateValues, setStateValues] = useState({
        key1: "",
        key2: "",
        key3: "",
    });
    const [comperfile, setComperfile] = useState(null);
    const [comperfile2, setComperfile2] = useState(null);
    const [comperfile3, setComperfile3] = useState(null);
    const [load, setLoad] = useState(false);
    const [firstkey1, setfirstkey1] = useState("");
    const [firstkey2, setfirstkey2] = useState("");
    const [firstkey3, setfirstkey3] = useState("");
    const fieldContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
    };
    const ButtonStyle = {
        marginTop: 1,
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#3c82ea",
        borderRadius: "15px",
        px: 3,
        width: 250
    };
    const handelclearfile = () => {
        setLoad(false);
        drop.current.value = "";
        inputRef.current.value = "";
        setStateValues({ ...stateValues, key1: "" });
        setComperfile(null);
    };
    const handelclearfile2 = () => {
        setLoad(false);
        drop2.current.value = "";
        inputRef2.current.value = "";
        setStateValues({ ...stateValues, key2: "" });
        setComperfile2(null);
    };
    const handelclearfile3 = () => {
        setLoad(false);
        drop3.current.value = "";
        inputRef3.current.value = "";
        setStateValues({ ...stateValues, key3: "" });
        setComperfile3(null);
    };
    const convertExcelToJson = (file, callBack, sheetNo = 0) => {
        try {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    // const wsName = wb.SheetNames[0];
                    const wsName = wb.SheetNames[sheetNo];
                    console.log(wsName, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                    const ws = wb.Sheets[wsName];
                    const data = XLSX.utils.sheet_to_json(ws, { defval: null })
                    console.log(data, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                    resolve(data);
                    fileReader.onerror = (e) => {
                        reject(e)
                    }
                }
            })
            promise.then(res => {
                callBack && callBack(res)
            }).catch(() => setLoad(false))

        } catch (error) {
            setLoad(false);
        }
    }
    const hangelFile = (e) => {
        setLoad(true);
        const file = e.target?.files[0];
        setStateValues({ ...stateValues, key1: file });
        convertExcelToJson(file, (res) => setComperfile(res))
        setLoad(false);
    };
    const hangelFile2 = (e) => {
        console.log(e.target.files[0], 'dfcghvbjn');
        setLoad(true);
        const file = e.target?.files[0];
        setStateValues({ ...stateValues, key2: file });
        convertExcelToJson(file, (res) => setComperfile2(res))
        setLoad(false);
    };
    const hangelFile3 = (e) => {
        setLoad(true);
        const file = e.target?.files[0];
        setStateValues({ ...stateValues, key3: file });
        convertExcelToJson(file, (res) => setComperfile3(res), 3)
        setLoad(false);
    };
    const [isError, setIsError] = useState(false);
    function splitArrayByStep(arr, step) {
        const result = [];
        for (let i = 0; i < arr.length; i += step) {
            const t = arr.slice(i, i + step);
            if (t.length <= SPLIT_GRACE_VALUE && result.length) {
                result[result.length - 1] = [...result[result.length - 1], ...t]

            } else result.push(arr.slice(i, i + step));
        }
        return result;
    }
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    function pause(msec) {
        return new Promise(
            (resolve, reject) => {
                setTimeout(resolve, msec || 1000);
            }
        );
    }
    const showErrorMsg = (msg) => {
        setIsError(msg);
        // setTimeout(() => setIsError(false), 1000);
    }
    const handelSubmit = async () => {
        try {
            if (!comperfile) return showErrorMsg('Kindly Select Final File');
            if (!comperfile2) return showErrorMsg('Kindly Select Input File');
            if (comperfile?.length) {
                const t = Object.keys(comperfile[0]).filter(i => !i.includes('__EMPTY'));
                const columnDa = COLUMN_DETAILS.getcolumnNames();
                if (columnDa?.length !== t.length) {
                    // if (HEADER_LIST.length !== t.length && t.length !== 21 && t.length !== 22) {
                    return  showErrorMsg('Please Provide the Valid Template for final file');

                }
                const te = [...columnDa, COLUMN_DETAILS.columnData.amount_1.validationName]
                const matches = t.filter((element) => te?.includes(element));
                if (t.length === matches.length && t.length === COLUMN_DETAILS.getcolumnNames()?.length) console.log('h')
                else {
                    return showErrorMsg('Please Provide the Valid Template for final file');
                }
            }
            if (comperfile2?.length && comperfile?.length) {
                const t = Object.keys(comperfile2[0]);
                if (t.includes('FOLDER') && t.includes('File Name')) {

                    const missingNames = comperfile2.filter(i => {
                        const isFind = comperfile.find(data => data['FOLDER'] === i['FOLDER'] && data['File Name'] === i['File Name'] && (data['FOLDER'] && data['File Name']))
                        if (!isFind) return true
                    })
                    const missingNames2 = [];
                    const commentErrorList = [];
                    let commentCheckList = [];
                    comperfile.filter(i => {
                        const isFind = comperfile2.find(data => data['FOLDER'] === i['FOLDER'] && data['File Name'] === i['File Name'] && (i['FOLDER'] && i['File Name']))
                        //   QC Check
                        if (i['Additional Fields'] === "amount") {
                            const keyList = Object.keys(i).filter(j => j?.includes('__EMPTY') && (i[j] || i[j] == 0));
                            if (keyList?.length > 99 && i['Comment'] !== totalExceedCmt) {
                                commentErrorList.push({
                                    ['S.No']: i['S.No'],
                                    ['D.S.No']: i['D.S.No'],
                                    ['File Name']: i['File Name'], 
                                    imagePath: i['Image Path'],
                                    ['FieldName']: 'Comment',
                                    keyedValue: i['Comment'],
                                    Msg: `20.4 - AdditionalField - amount count is more than 99,Kindly update comment as "${totalExceedCmt}" in final sheet.`,
                                    Type: 'Error'
                                })
                            } else if (i['Comment'] === totalExceedCmt && keyList?.length < 99) {
                                commentErrorList.push({
                                    ['S.No']: i['S.No'],
                                    ['D.S.No']: i['D.S.No'],
                                    ['File Name']: i['File Name'], 
                                    imagePath: i['Image Path'],
                                    ['FieldName']: 'Comment',
                                    keyedValue: i['Comment'],
                                    Msg: `20.5 - AdditionalField - amount count should be more than 99 for the comment keyed as "${totalExceedCmt}".So, comment has been removed in datasheet.Kindly check.`,
                                    Type: 'Error'
                                })
                            }
                        }
                        //  adding comment check sheet
                        if (i['File Name'] && i['Comment']) {
                            const t = [cmt, cmt2].includes(i['Comment'])
                            if ([cmt, cmt2].includes(i['Comment'])) {
                                commentCheckList.push({
                                    ['S.No']: i['S.No'],
                                    ['D.S.No']: i['D.S.No'],
                                    TYPE: i['TYPE'],
                                    FOLDER: i['FOLDER'],
                                    ['File Name']: i['File Name'],
                                    ['FieldName']: 'Comment',
                                    keyedValue: i['Comment'],
                                    ['Checked(Y/N)']: 'N'
                                })
                            }

                        }
                        //   QC Check
                        if (!isFind && i['File Name']) {
                            missingNames2.push({
                                ['S.No']: i['S.No'],
                                ['D.S.No']: i['D.S.No'],
                                TYPE: i['TYPE'],
                                FOLDER: i['FOLDER'],
                                ['File Name']: i['File Name']
                            })
                            return true;
                        }
                        else return false
                    })
                    // check if all comment check rows or checked
                    if (comperfile3?.length) {
                        const isOk = commentCheckList.map(i => {
                            const isChecked = comperfile3.find(j => (j[COLUMN_DETAILS.columnData.DSNo.name] === i[COLUMN_DETAILS.columnData.DSNo.name] && (j['Checked(Y/N)']?.toLowerCase() === 'y' || j['Checked(Y/N)']?.toLowerCase() === 'yes')))
                            if (!isChecked) return i
                            else return isChecked
                        })
                        commentCheckList = [...isOk]
                    }
                    const tds =commentCheckList?.find(j => (j['Checked(Y/N)']?.toLowerCase() !== 'y' && j['Checked(Y/N)']?.toLowerCase() !== 'yes'))
                    if (missingNames?.length || missingNames2?.length || commentErrorList?.length || (commentCheckList?.length && tds)) {
                     
                        const ws = XLSX.utils.json_to_sheet([...missingNames], { origin: 'A2', skipHeader: true });
                        XLSX.utils.sheet_add_aoa(ws, [['TYPE', 'FOLDER', 'FileName']], { origin: 'A1' });
                        const ws2 = XLSX.utils.json_to_sheet([...missingNames2], { origin: 'A2', skipHeader: true });
                        XLSX.utils.sheet_add_aoa(ws2, [['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'FileName']], { origin: 'A1' });
                        const ws3 = XLSX.utils.json_to_sheet([...commentErrorList], { origin: 'A2', skipHeader: true });
                        XLSX.utils.sheet_add_aoa(ws3, [['S.No', 'D.S.No', 'FileName', 'imagePath', 'FieldName', 'keyedValue', 'Msg', 'Type']], { origin: 'A1' });
                        const ws4 = XLSX.utils.json_to_sheet([...commentCheckList], { origin: 'A2', skipHeader: true });
                        XLSX.utils.sheet_add_aoa(ws4, [['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'File Name', 'FieldName', 'keyedValue', 'Checked(Y/N)']], { origin: 'A1' });
               


                        const wb = { Sheets: { DAT_CHECK: ws, FinalFile_Unwanted_FileNames: ws2, Comment_Mismatch: ws3, Comment_Check: ws4 }, SheetNames: ["DAT_CHECK", "FinalFile_Unwanted_FileNames", "Comment_Mismatch", "Comment_Check"] };
                        const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
                        const data = await new Blob([excelBuffer], { type: fileType });
                        FileSaver.saveAs(data, `Missing_FileName_Sheet`);
                    } else {
                        let keyList = COLUMN_DETAILS.getcolumnNames();
                        console.log(keyList, '635w4seytrdfyguiuhkjn');
                        // must be reverse index order
                        const removeValFromIndex = [22, 21, 2, 1];

                        removeValFromIndex.forEach(i => {
                            keyList?.splice(i, 1);
                        })
                        // replacing column names
                        keyList.splice(0, 1, 'S.No')
                        keyList.splice(1, 1, 'Batch')
                        keyList.splice(2, 1, 'Image Path')
                        keyList.splice(3, 1, 'PDF Name')
                        keyList.splice(10, 1, 'amount')
                        keyList.splice(19, 1, '-')
                        console.log(keyList, '635w4seytrdfyguiuhkjn');
                        const test = comperfile.map(i => {
                            let t = { ...i };
                            const customObj = {
                            }
                            let t1 = Object.keys(t);
                            removeValFromIndex.forEach(i => {
                                t1?.splice(i, 1);
                            })
                            console.log(t1, '6e4drtyfguiubhjk');
                            t1.map(i => {
                                const tName = i === "Image Path" ? "File Name" : i === "File Name" ? "" : i
                                customObj[i] = t[tName] || null;
                            })
                            // t.splice(1,0,'Test1')
                            return customObj
                        })
                        console.log(test, 'missingNamesmissingNamesmissingNames2342343');
                        const ws = XLSX.utils.json_to_sheet([...test], { origin: 'A2', skipHeader: true });
                        XLSX.utils.sheet_add_aoa(ws, [keyList], { origin: 'A1' });

                        // const ws2 = XLSX.utils.json_to_sheet([...commentCheckList], { origin: 'A2', skipHeader: true });
                        // XLSX.utils.sheet_add_aoa(ws, [['S.No', 'D.S.No', 'FileName','FieldName', 'keyedValue', 'Checked']], { origin: 'A1' });
                        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                        const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
                        const data = await new Blob([excelBuffer], { type: fileType });
                        FileSaver.saveAs(data, `Final_Sheet`);
                    }
                } else return showErrorMsg('Please Provide the Valid Template for input file, Download the Template Above For Your Reference')

            }
            console.log(comperfile, 'erghjbkn');
            console.log(comperfile2, 'erghjbkn');
        }
        catch (error) {
            console.log(error, 'errorerrorerror');
            setLoad(false);
        }
        setLoad(false);
        setCount('');
        setPath('')
        handelclearfile()
        handelclearfile2()
        handelclearfile3()
        setStateValues({ key1: '', key2: '', key3: '' });
        setComperfile(null);
        setComperfile2(null);
        setComperfile3(null);
    }

    React.useEffect(() => {
        drop.current.addEventListener("dragover", handleDragOver);
        drop.current.addEventListener("drop", handleDrop);
        drop2.current.addEventListener("dragover", handleDragOver);
        drop2.current.addEventListener("drop", handleDrop2);
        drop3.current.addEventListener("dragover", handleDragOver);
        drop3.current.addEventListener("drop", handleDrop3);
        return () => {
            drop?.current?.removeEventListener("dragover", handleDragOver);
            drop?.current?.removeEventListener("drop", handleDrop);
            drop2?.current?.removeEventListener("dragover", handleDragOver);
            drop2?.current?.removeEventListener("drop", handleDrop2);
            drop3?.current?.removeEventListener("dragover", handleDragOver);
            drop3?.current?.removeEventListener("drop", handleDrop3);
        };
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '12345');
            setfirstkey1(files[0]);
        }
    };
    const handleDrop2 = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '123459879');
            setfirstkey2(files[0]);
        }
    };
    const handleDrop3 = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '123459879');
            setfirstkey3(files[0]);
        }
    };
    useEffect(() => {
        if (firstkey1) {
            try {
                setLoad(true);
                setStateValues({ ...stateValues, key1: firstkey1 });
                convertExcelToJson(firstkey1, (res) => setComperfile(res));
                setLoad(false);
            } catch (error) {
                setLoad(false);
            }
        }
    }, [firstkey1]);
    useEffect(() => {
        if (firstkey2) {
            try {
                setLoad(true);
                setStateValues({ ...stateValues, key2: firstkey2 });
                convertExcelToJson(firstkey2, (res) => setComperfile2(res));
                setLoad(false);
            } catch (error) {
                setLoad(false);
            }
        }
    }, [firstkey2]);
    useEffect(() => {
        if (firstkey3) {
            try {
                setLoad(true);
                setStateValues({ ...stateValues, key3: firstkey2 });
                convertExcelToJson(firstkey3, (res) => setComperfile3(res));
                setLoad(false);
            } catch (error) {
                setLoad(false);
            }
        }
    }, [firstkey3]);

    const [count, setCount] = useState('');
    const [path, setPath] = useState('');
    const downloadTemplate = async () => {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Sheet1');
        let headerRow = worksheet.addRow(FINAL_COLUMN);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'FileName_Check_Template');
        })
    }
    return (
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
            <Box sx={{ pt: 2 }}>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Lato",
                        fontSize: "1.5rem",
                        p: 1,
                    }}
                >
                    Dispatch File Generation
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={downloadTemplate}>
                        <DownloadIcon fontSize={'20'} /><Typography>Download Input File Template</Typography>
                    </IconButton>

                </Box>
                <Card
                    sx={{ p: 4, backgroundColor: "#f0fefc", border: "1px solid #3c82ea" }}
                >
                    <Box ref={drop} sx={fieldContainer}>
                        {" "}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                key={6534675}
                                type="file"
                                accept=".xlsx"
                                id="select-image"
                                style={{ display: "none" }}
                                ref={inputRef}
                                onChange={(e) => hangelFile(e)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select Final file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lato",
                                            textAlign: "justify",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {stateValues?.key1?.name ? stateValues?.key1?.name : null}
                                    </Typography>
                                    {stateValues.key1 ? (
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                            onClick={() => {
                                                handelclearfile();
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box ref={drop2} sx={fieldContainer}>
                        {" "}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                key={546767}
                                type="file"
                                accept=".xlsx"
                                id="select-image2"
                                style={{ display: "none" }}
                                ref={inputRef2}
                                onChange={(e) => hangelFile2(e)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image2">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select Input file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lato",
                                            textAlign: "justify",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {stateValues?.key2?.name ? stateValues?.key2?.name : null}
                                    </Typography>
                                    {stateValues.key2 ? (
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                            onClick={() => {
                                                handelclearfile2();
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box ref={drop3} sx={fieldContainer}>
                        {" "}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                key={546767}
                                type="file"
                                accept=".xlsx"
                                id="select-image3"
                                style={{ display: "none" }}
                                ref={inputRef3}
                                onChange={(e) => hangelFile3(e)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image3">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select Comment file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lato",
                                            textAlign: "justify",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {stateValues?.key3?.name ? stateValues?.key3?.name : null}
                                    </Typography>
                                    {stateValues.key3 ? (
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                            onClick={() => {
                                                handelclearfile3();
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={fieldContainer}>
                        {load ? <Button
                            variant="contained"
                            size="small"
                            component="span"
                            sx={{
                                marginTop: 1,
                                textTransform: "capitalize",
                                backgroundColor: "#3c82ea",
                                fontFamily: "Lato",
                                borderRadius: "15px",
                                px: 8,
                                py: 1.5,

                                width: 250
                            }}
                        >
                            <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} />
                        </Button>
                            :
                            <Button
                                variant="contained"
                                size="small"
                                component="span"
                                onClick={() => handelSubmit()}
                                sx={{
                                    marginTop: 1,
                                    textTransform: "capitalize",
                                    backgroundColor: "#3c82ea",
                                    fontFamily: "Lato",
                                    borderRadius: "15px",
                                    px: 8,
                                    py: 1.5,

                                    width: 250
                                }}
                            >
                                Submit
                            </Button>}
                    </Box>

                    {isError ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Alert severity="error">{isError}</Alert>
                            <CloseIcon
                                sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                onClick={() => {
                                    setIsError(false);
                                }}
                            />
                        </Box>
                        : null}
                </Card>
            </Box>
        </Box>
    );
}

export default FinalSheetScreen;
