import { Box, Card, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getVendorNameListService, uploadVendorNameFileService } from '../service/materService';

const VendorNameMaster = () => {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 110,
            editable: true,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
    ];
    const rows = [
        { id: 1, lastName: 'M', firstName: 'jagadesh', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];
    useEffect(() => {
        getVendorNameListService()
    },[])
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        uploadVendorNameFileService(file);
      };
    return (
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
            <Box sx={{ pt: 2, width: '70%', display:'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Lato",
                        fontSize: "1.5rem",
                        p: 1,
                    }}
                >
                    Vendor Name List
                </Typography>
                <Box>
                    <input type={'file'} onChange={handleFileChange}/>
                </Box>
                <Card
                    sx={{height: 600, width: '100%' }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        // checkboxSelection
                        // disableRowSelectionOnClick
                    />
                </Card>
            </Box>
        </Box>
    )
}

export default VendorNameMaster;