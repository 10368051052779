import "./App.css";
import { Box } from "@mui/material";
import NavBar from "./components/appBar";
import InvoiceFormComp from "./components/invoiceForm/form";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { createBrowserHistory } from 'history';
import { useSelector } from "react-redux";
import LoginScreen from "./screens/LoginScreen";
import HeadNavbar from "./components/NavBar";
import CreateJop from "./screens/CreateJop";
import Assignment from "./screens/Assignment";
import Home from "./screens/Home";
import Footer from "./screens/Footer";
import Compare from "./screens/Compare";
import SplitFileScreen from "./screens/split/splitFile";
import JoinFileScreen from "./screens/fileJoin";
import QcFileScreen from "./screens/qch/qch";
import FinalSheetScreen from "./screens/finalSheet";
import VendorNameMaster from "./screens/VendorMaster";
// export const history = createBrowserHistory();
const App = () => {
  // const { isLogin } = useSelector((state) => state.login);
  const { isLogin } = useSelector((state) => state.common);
  return (
    // <Box  className="main-container">
    //   <Box className="flex flex-1">
    //     <InvoiceFormComp />
    //   </Box>

    // </Box>
    <Box className="main-container">
      <Router>
        {/* {isLogin ? (
          <>
            <HeadNavbar />
            <Routes>
              <Route path="/from" element={<InvoiceFormComp />} />
              <Route path="/compare/doc" element={<InvoiceFormComp />} />
              <Route path="/job" element={<CreateJop />} />
              <Route path="/assign" element={<Assignment />} />
              <Route path="/compare" element={<Compare />} />
              <Route path="/split" element={<SplitFileScreen />} />
              <Route path="/combine" element={<JoinFileScreen />} />
              <Route path="/qc" element={<QcFileScreen />} />
              <Route path="/merge" element={<QcFileScreen />} />
              <Route path="*" element={<Navigate to="/job" replace />} />
            </Routes>
            <Footer />
          </>
        ) : (
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )} */}

        {/* temp */}
        {isLogin ? <>
          <HeadNavbar />
          <Routes>
            {/* <Route path='/' element={<Navigate to="/home" replace />} /> */}
            {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path="/from" element={<InvoiceFormComp />} />
            <Route path="/compare/doc" element={<InvoiceFormComp />} />
            <Route path="/job" element={<CreateJop />} />
            <Route path="/assign" element={<Assignment />} />
            <Route path="/compare" element={<Compare />} /> */}
            <Route path="/split" element={<SplitFileScreen />} />
            <Route path="/combine" element={<JoinFileScreen />} />
            <Route path="/qc" element={<QcFileScreen />} />
            <Route path="/merge" element={<QcFileScreen />} />
            <Route path="/final" element={<FinalSheetScreen />} />
            <Route path="/master/vendor" element={<VendorNameMaster />} />
            <Route path="*" element={<Navigate to="/split" replace />} />
          </Routes>
          <Footer />
        </>

          :
          <>
            <Routes>
              <Route path="/" element={<LoginScreen />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </>
        }
        {/* temp */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </Box>
  );
};

export default App;
