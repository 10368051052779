import { createSlice } from "@reduxjs/toolkit";

export const stylenav = createSlice({
  name: "stylenav",
  initialState: {
    navBarOpen: false,
  },
  reducers: {
    updateStyleReducer: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { updateStyleReducer } = stylenav.actions;

export default stylenav.reducer;
