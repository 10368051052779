import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import invoiceFormReducer from "./reducers/invoiceFormReducer";
import loginReducer from "./reducers/loginReducer";
import commonReducer from "./reducers/commonReducer";
import styleReducer from "./reducers/styleReducer";
import updateJobFormReducer from "./reducers/JobReducer";

export const addUserLogoutRequest = (Id) => ({
  type: "USER_LOGUT_REQUEST",
  payload: Id,
});

const reducers = combineReducers({
  invoice: invoiceFormReducer,
  login: loginReducer,
  common: commonReducer,
  normal: styleReducer,
  job: updateJobFormReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGUT_REQUEST") {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["invoice", "login", "common", "job"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
});
