import {
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    IconButton,
    InputBase,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import file from "../../asstes/images/file.png";
import upload from "../../asstes/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import FileSaver from "file-saver";
import moment from "moment";
import { COLUMN_DETAILS, EMPLOYEE_NAME_LIST, SPLIT_GRACE_VALUE, TRACKER_COLUMN_DETAILS, TRACKER_STATUS } from "./config";
import { columnCheck, COMMENTS_EXCEL, MEMO_LIST_EXCEL, SPLIT_COLUMN, SPLIT_TEMPLATE } from "../qch/validation";
var _ = require('lodash');

function SplitFileScreen() {
    const inputRef = useRef(null);
    const drop = useRef(null);
    const [stateValues, setStateValues] = useState({
        key1: "",
        key2: "",
    });
    const [comperfile, setComperfile] = useState(null);
    const [load, setLoad] = useState(false);
    const [firstkey1, setfirstkey1] = useState("");
    const fieldContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
    };
    const ButtonStyle = {
        marginTop: 1,
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#3c82ea",
        borderRadius: "15px",
        px: 3,
    };
    const handelclearfile = () => {
        setLoad(false);
        drop.current.value = "";
        inputRef.current.value = "";
        setStateValues({ ...stateValues, key1: "" });
        setComperfile(null);
    };
    const convertExcelToJson = (file, callBack) => {
        try {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    // const wsName = wb.SheetNames[0];
                    const wsName = wb.SheetNames[0];
                    console.log(wsName, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                    const ws = wb.Sheets[wsName];
                    const data = XLSX.utils.sheet_to_json(ws)
                    console.log(data, 'wb.SheetNameswb.SheetNameswb.SheetNames');
                    resolve(data);
                    fileReader.onerror = (e) => {
                        reject(e)
                    }
                }
            })
            promise.then(res => {
                callBack && callBack(res)
            }).catch(() => setLoad(false))

        } catch (error) {
            setLoad(false);
        }
    }
    const hangelFile = (e) => {
        console.log(e.target.files[0], 'dfcghvbjn');
        setLoad(true);
        const file = e.target?.files[0];
        setStateValues({ ...stateValues, key1: file });
        convertExcelToJson(file, (res) => setComperfile(res))
        setLoad(false);
    };
    const [isError, setIsError] = useState(false);
    function splitArrayByStep(arr, step) {
        const result = [];
        for (let i = 0; i < arr.length; i += step) {
            const t = arr.slice(i, i + step);
            if (t.length <= SPLIT_GRACE_VALUE && result.length) {
                result[result.length - 1] = [...result[result.length - 1], ...t]

            } else result.push(arr.slice(i, i + step));
        }
        return result;
    }
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    function pause(msec) {
        return new Promise(
            (resolve, reject) => {
                setTimeout(resolve, msec || 1000);
            }
        );
    }
    const showErrorMsg = (msg) => {
        setIsError(msg);
        // setTimeout(() => setIsError(false), 1000);
    }
    const handelSubmit = async () => {
        try {
            if (!comperfile) return showErrorMsg('Kindly Select File');
            if (count === '') return showErrorMsg('Kindly Enter Count');
            if (path === '') return showErrorMsg('Kindly Enter Path');
            if (count || count === 0) {
                setLoad(true);
                const sortedArray = comperfile.sort((a, b) => a.TYPE > b.TYPE ? 1 : -1);
                // adding image path column
                const pathAdded = sortedArray?.map(data => {
                    const t = { ...data };
                    const tPath = path?.replace(/[/\\]+$/, '')
                    t[COLUMN_DETAILS.columnData.imagePath.name] = `${tPath}\\${data['TYPE']}\\${data['FOLDER']}\\${data['File Name']}`
                    return t
                })
                const res = await columnCheck(comperfile, showErrorMsg, setLoad);
                if (!res) return;
                // const divide = Math.round(+comperfile.length / +count);
                const separatedArrays = pathAdded.reduce((accumulator, current) => {
                    const index = accumulator.findIndex(item => {
                        // 1 & 1b are same
                        // const fValue = item[0].TYPE.replace(/[a-zA-Z]/g, "");
                        // const sValue = current.TYPE.replace(/[a-zA-Z]/g, "");

                        // 1 & 1b are not same
                        const fValue = item[0].TYPE
                        const sValue = current.TYPE
                        if (fValue === sValue) return true
                    });
                    if (index >= 0) {
                        accumulator[index].push(current);
                    } else {
                        accumulator.push([current]);
                    }
                    return accumulator;
                }, []);
                // const subArrays = splitArrayByStep(sortedArray, Number(count));
                const splitedArr = separatedArrays?.map(arr => { return splitArrayByStep(arr, Number(count)) });
                const finalArr = [].concat(...splitedArr);
                let dsNo = 0;
                let sNo = 0;

                // Assignment Tracker sheet
                let AssignmentData = [];
                let workbook2 = new Workbook();
                let worksheet2 = workbook2.addWorksheet('Sheet1');
                const headerRow1 = worksheet2.addRow(['Input', null, null, null, null, 'Keying', null, null, null, null, null, null, null, 'Final']);
                worksheet2.mergeCells('A1:E1')
                worksheet2.mergeCells('F1:M1')
                worksheet2.mergeCells('N1:U1')
                worksheet2.getCell('A1').alignment = { horizontal: 'center' }
                worksheet2.getCell('F1').alignment = { horizontal: 'center' }
                worksheet2.getCell('N1').alignment = { horizontal: 'center' }
                headerRow1.eachCell((cell, number) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF00' },
                        // bgColor: { argb: '#FFFFFF' }
                    }
                    cell.font = {
                        // color: { argb: 'FFFFFF00' }, // white text
                        bold: true
                    }
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });

                let headerRow2 = worksheet2.addRow(TRACKER_COLUMN_DETAILS.getcolumnNames());
                headerRow2.eachCell((cell, number) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF00' },
                        bgColor: { argb: 'FF0000FF' }
                    }
                    // cell.font = {
                    //     color: { argb: 'FFFFFF00' }, // white text
                    //     bold: true
                    // }
                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                });
                // Assignment Tracker sheet
                for (var e in finalArr) {
                    const test = finalArr[e];
                    const nameArr = test.map((i) => i.TYPE);
                    const uniq = [...new Set(nameArr)];
                    const folderName = uniq?.join('-');
                    await pause(1000);
                    let st, end;

                    //Create workbook and worksheet
                    let workbook = new Workbook();
                    let worksheet = workbook.addWorksheet('Sheet1');
                    //Add Header Row
                    let headerRow = worksheet.addRow(COLUMN_DETAILS.getcolumnNames());
                    // worksheet.getColumn(3).width = 30;
                    // Cell Style : Fill and Border
                    headerRow.eachCell((cell, number) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFFF00' },
                            bgColor: { argb: 'FF0000FF' }
                        }
                        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                    })

                    // allocation details
                    test.forEach(async (item, index) => {
                        dsNo += 1
                        if (index === 0) st = dsNo;
                        if (index + 1 === test.length) end = dsNo;
                        worksheet.addRow(
                            [
                                sNo + 1,
                                dsNo,
                                item.TYPE,
                                item.FOLDER,
                                item['File Name'],
                                item[COLUMN_DETAILS.columnData.imagePath.name],
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                item['File Name'],
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                "amount",
                            ]).getCell(COLUMN_DETAILS.columnData.memo.columnPosition).dataValidation = {
                            type: 'list',
                            allowBlank: true,
                            formulae: MEMO_LIST_EXCEL,
                            showErrorMessage: true,
                            showInputMessage: true,
                        };
                        worksheet.addRow([
                            sNo + 2,
                            null,
                            null,
                            item.FOLDER,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "memo",
                        ]).getCell(COLUMN_DETAILS.columnData.memo.columnPosition).dataValidation = {
                            type: 'list',
                            allowBlank: true,
                            formulae: MEMO_LIST_EXCEL,
                            showErrorMessage: true,
                            showInputMessage: true,

                        };
                        worksheet.addRow([
                            sNo + 3,
                            null,
                            null,
                            item.FOLDER,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "quantity",
                        ]);
                        worksheet.addRow([
                            sNo + 4,
                            null,
                            null,
                            item.FOLDER,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "Cost",
                        ])
                        sNo += 4;

                    }
                    );

                    for (let x = 1; x <= (test.length * 4) + 1; x++) {
                        // worksheet.getRow(x).getCell('M').dataValidation = {
                        //     type: 'list',
                        //     allowBlank: true,
                        //     formulae: MEMO_LIST_EXCEL
                        // };
                        (worksheet.getRow(x).getCell(COLUMN_DETAILS.columnData.Comment.columnPosition).dataValidation = {
                            type: 'list',
                            allowBlank: true,
                            formulae: COMMENTS_EXCEL,
                            showErrorMessage: true,
                            showInputMessage: true,
                        });
                        (worksheet.getRow(x).getCell(COLUMN_DETAILS.columnData.issuedDate.columnPosition).numFmt = "@");
                        // const t = worksheet.getRow(x).getCell(`T`);
                        // t.dataValidation = {
                        //     type: 'list',
                        //     allowBlank: true,
                        //     formulae: COMMENTS_EXCEL,
                        //     showErrorMessage: true,
                        //     showInputMessage: true,
                        // };
                        // t.protection = {
                        //     locked: true,
                        //     formulaHidden: true,
                        // }
                    }
                    // TEST

                    // TEST
                    worksheet.getColumn(COLUMN_DETAILS.columnData.issuedDate.columnPosition).numFmt = "@";
                    worksheet.getColumn(COLUMN_DETAILS.columnData.dueDate.columnPosition).numFmt = "@";

                    const fName = `${Number(e) + 1}_Batch_F${folderName}_L${st}-${end}_${moment().format('DDMMYYYY')}` + fileExtension;

                    // adding data to second sheet
                    AssignmentData.push({
                        type: folderName?.includes('b') ? 'B' : 'N',
                        folder: folderName,
                        fileName: fName,
                        count: (end - st) ? (end - st) + 1 : 0,

                    })
                    workbook.xlsx.writeBuffer().then((data) => {
                        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(blob, fName);
                    })
                    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                    // const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
                    // const data = await new Blob([excelBuffer], { type: fileType });
                    // // const fName= `${moment().format('DD-MM-YYYY')}_${folderName}_Batch_${Number(e) + 1}` + fileExtension
                    // const fName = `${Number(e) + 1}_Batch_F${folderName}_L${st}-${end}_${moment().format('DDMMYYYY')}` + fileExtension
                    // FileSaver.saveAs(data, fName)
                }
                AssignmentData.forEach((data, index) => {
                    worksheet2.addRow([
                        index + 1,
                        data.type,
                        data.folder,
                        data.fileName,
                        data.count,
                        null
                    ])
                });
                for (let x = 3; x <= (AssignmentData.length) + 2; x++) {
                    (worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Name.position).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        formulae: EMPLOYEE_NAME_LIST,
                    });
                    (worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Name2.position).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        formulae: EMPLOYEE_NAME_LIST,
                    });
                    (worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.status.position).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        formulae: TRACKER_STATUS,
                    });
                    (worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.status2.position).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        formulae: TRACKER_STATUS,
                    });
                    const cellHrs = worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Hrs.position);
                    cellHrs.value = { formula: `${TRACKER_COLUMN_DETAILS.columnData.End.position}${x} - ${TRACKER_COLUMN_DETAILS.columnData.Start.position}${x}` }
                    const cellBal = worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Bal.position);
                    cellBal.value = { formula: `${TRACKER_COLUMN_DETAILS.columnData.count.position}${x} - ${TRACKER_COLUMN_DETAILS.columnData.Com.position}${x}`, result: AssignmentData[x - 3].count }
                    //    next set
                    const cellHrs2 = worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Hrs2.position);
                    cellHrs2.value = { formula: `${TRACKER_COLUMN_DETAILS.columnData.End2.position}${x} - ${TRACKER_COLUMN_DETAILS.columnData.Start2.position}${x}` }
                    const cellBal2 = worksheet2.getRow(x).getCell(TRACKER_COLUMN_DETAILS.columnData.Bal2.position);
                    cellBal2.value = { formula: `${TRACKER_COLUMN_DETAILS.columnData.count.position}${x} - ${TRACKER_COLUMN_DETAILS.columnData.Com2.position}${x}`, result: AssignmentData[x - 3].count }

                }
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.Start.position).numFmt = 'hh:mm';
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.End.position).numFmt = 'hh:mm';
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.Hrs.position).numFmt = 'hh:mm';
                // next set
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.Start2.position).numFmt = 'hh:mm';
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.End2.position).numFmt = 'hh:mm';
                worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.Hrs2.position).numFmt = 'hh:mm';
                // let columnSts = worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.status.position);
                // columnSts.eachCell((cell, number) => {
                //     cell.fill = {
                //         type: 'pattern',
                //         pattern: 'solid',
                //         fgColor: { argb: '00000000' },
                //         bgColor: { argb: 'FF0000FF' }
                //     }
                //     cell.font = {
                //         color: { argb: 'FFFFFF00' }, // white text
                //         bold: true
                //     }
                //     cell.border = { top: { style: 'thin', color: { argb: 'FFFFFF00' } }, left: { style: 'thin', color: { argb: 'FFFFFF00' } }, bottom: { style: 'thin', color: { argb: 'FFFFFF00' } }, right: { style: 'thin', color: { argb: 'FFFFFF00' } } }
                // });
                // let columnSts2 = worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.status2.position);
                // columnSts2.eachCell((cell, number) => {
                //     cell.fill = {
                //         type: 'pattern',
                //         pattern: 'solid',
                //         fgColor: { argb: '00000000' },
                //         bgColor: { argb: 'FF0000FF' }
                //     }
                //     cell.font = {
                //         color: { argb: 'FFFFFF00' }, // white text
                //         bold: true
                //     }
                //     cell.border = { top: { style: 'thin', color: { argb: 'FFFFFF00' } }, left: { style: 'thin', color: { argb: 'FFFFFF00' } }, bottom: { style: 'thin', color: { argb: 'FFFFFF00' } }, right: { style: 'thin', color: { argb: 'FFFFFF00' } } }
                // });
                // let columnSNo = worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.sNo.position);
                // columnSNo.eachCell((cell, number) => {
                //     cell.fill = {
                //         type: 'pattern',
                //         pattern: 'solid',
                //         fgColor: { argb: '00000000' },
                //         bgColor: { argb: 'FF0000FF' }
                //     }
                //     cell.font = {
                //         color: { argb: 'FFFFFF00' }, // white text
                //         bold: true
                //     }
                //     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                // });
                // let columnCount = worksheet2.getColumn(TRACKER_COLUMN_DETAILS.columnData.count.position);
                // columnCount.eachCell((cell, number) => {
                //     cell.fill = {
                //         type: 'pattern',
                //         pattern: 'solid',
                //         fgColor: { argb: '00000000' },
                //         bgColor: { argb: 'FF0000FF' }
                //     }
                //     cell.font = {
                //         color: { argb: 'FFFFFF00' }, // white text
                //         bold: true
                //     }
                //     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                // });
                const fName = `Assignment_Tracker_${moment().format('DD-MM-YYYY')}` + fileExtension;
                workbook2.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, fName);
                })
            }
        } catch (error) {
            console.log(error, 'errorerrorerror');
            setLoad(false);
        }
        setLoad(false);
        setCount('');
        setPath('')
        handelclearfile()
    }
    // const handelSubmit = async () => {
    //     try {
    //         if (!comperfile) return showErrorMsg('Kindly Select File');
    //         if (count === '') return showErrorMsg('Kindly Enter Count');
    //         if (count || count === 0) {
    //             setLoad(true);
    //             const sortedArray = comperfile.sort((a, b) => a.TYPE > b.TYPE ? 1 : -1);
    //             // const divide = Math.round(+comperfile.length / +count);
    //             const separatedArrays = sortedArray.reduce((accumulator, current) => {
    //                 const index = accumulator.findIndex(item => {
    //                     const fValue = item[0].TYPE.replace(/[a-zA-Z]/g, "");
    //                     const sValue = current.TYPE.replace(/[a-zA-Z]/g, "");
    //                     if (fValue === sValue) return true
    //                 });
    //                 if (index >= 0) {
    //                     accumulator[index].push(current);
    //                 } else {
    //                     accumulator.push([current]);
    //                 }
    //                 return accumulator;
    //             }, []);
    //             // const subArrays = splitArrayByStep(sortedArray, Number(count));
    //             const splitedArr = separatedArrays?.map(arr => { return splitArrayByStep(arr, Number(count)) });
    //             const finalArr = [].concat(...splitedArr);
    //             let dsNo = 0;
    //             let sNo = 0;
    //             for (var e in finalArr) {
    //                 const test = finalArr[e];
    //                 await pause(1000);
    //                 const ws = XLSX.utils.json_to_sheet([]);
    //                 const nameArr = test.map((i) => i.TYPE);
    //                 const uniq = [...new Set(nameArr)];
    //                 const folderName = uniq?.join('-');
    //                 const range = XLSX.utils.sheet_add_aoa(
    //                     ws,
    //                     [
    //                         [
    //                             'S.No',
    //                             'D.S.No',
    //                             'TYPE',
    //                             'FOLDER',
    //                             'File Name',
    //                             'issuedDate',
    //                             'dueDate',
    //                             'amount',
    //                             'invoiceNumber',
    //                             'terms',
    //                             'poNumber',
    //                             'amount',
    //                             'memo',
    //                             'fileName',
    //                             'name',
    //                             'email',
    //                             'phone',
    //                             'taxId',
    //                             'customerAccount',
    //                             'Comment',
    //                             'CoderName',
    //                             'Remarks',
    //                             'Additional Fields'
    //                         ],
    //                     ],
    //                     { origin: "A1" }
    //                 );
    //                 let st, end;
    //                 test.forEach((item, index) => {
    //                     dsNo += 1
    //                     if (index === 0) st = dsNo;
    //                     if (index + 1 === test.length) end = dsNo;
    //                     // const cell = index === 0 ? index + 2 : (index * 4) + 2
    //                     XLSX.utils.sheet_add_aoa(
    //                         ws,
    //                         [
    //                             [
    //                                 sNo + 1,
    //                                 dsNo,
    //                                 item.TYPE,
    //                                 item.FOLDER,
    //                                 item['File Name'],
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 item['File Name'],
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 "amount",
    //                             ],
    //                             [
    //                                 sNo + 2,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 "memo",
    //                             ],
    //                             [
    //                                 sNo + 3,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 "quantity",
    //                             ],
    //                             [
    //                                 sNo + 4,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 null,
    //                                 "Cost",
    //                             ],
    //                         ],
    //                         { origin: -1 }
    //                     );
    //                     sNo += 4;
    //                 });
    //                 const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    //                 const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //                 const data = await new Blob([excelBuffer], { type: fileType });
    //                 // const fName= `${moment().format('DD-MM-YYYY')}_${folderName}_Batch_${Number(e) + 1}` + fileExtension
    //                 const fName = `${Number(e) + 1}_Batch_F${folderName}_L${st}-${end}_${moment().format('DDMMYYYY')}` + fileExtension
    //                 FileSaver.saveAs(data, fName)
    //             }

    //         }
    //     } catch (error) {
    //         setLoad(false)
    //     }
    //     setLoad(false);
    //     setCount('');
    //     handelclearfile()
    // }
    React.useEffect(() => {
        drop.current.addEventListener("dragover", handleDragOver);
        drop.current.addEventListener("drop", handleDrop);
        return () => {
            drop?.current?.removeEventListener("dragover", handleDragOver);
            drop?.current?.removeEventListener("drop", handleDrop);
        };
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            console.log(files, '12345');
            setfirstkey1(files[0]);
        }
    };
    useEffect(() => {
        if (firstkey1) {
            try {
                setLoad(true);
                setStateValues({ ...stateValues, key1: firstkey1 });
                convertExcelToJson(firstkey1, (res) => setComperfile(res));
                setLoad(false);
            } catch (error) {
                setLoad(false);
            }
        }
    }, [firstkey1]);

    const [count, setCount] = useState('');
    const [path, setPath] = useState('');
    const downloadTemplate = async () => {
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Sheet1');
        let headerRow = worksheet.addRow(SPLIT_COLUMN);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'Split_Template');
        })
    }
    return (
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
            <Box sx={{ pt: 2 }}>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Lato",
                        fontSize: "1.5rem",
                        p: 1,
                    }}
                >
                    Split File
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={downloadTemplate}>
                        <DownloadIcon fontSize={'20'} /><Typography>Download Template</Typography>
                    </IconButton>

                </Box>
                <Card
                    sx={{ p: 4, backgroundColor: "#f0fefc", border: "1px solid #3c82ea" }}
                >
                    <Box ref={drop} sx={fieldContainer}>
                        {" "}
                        <Box
                            component="form"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="file"
                                accept=".xlsx"
                                id="select-image"
                                style={{ display: "none" }}
                                ref={inputRef}
                                onChange={(e) => hangelFile(e)}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        component="span"
                                        sx={ButtonStyle}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    pr: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={upload} style={{ height: 25 }} alt="uplaod" />
                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontFamily: "Lato" }}
                                                >
                                                    Select file
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: "10px", fontFamily: "Lato" }}
                                                >
                                                    or Drag and Drop
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </label>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lato",
                                            textAlign: "justify",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {stateValues?.key1?.name ? stateValues?.key1?.name : null}
                                    </Typography>
                                    {stateValues.key1 ? (
                                        <DeleteIcon
                                            sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                            onClick={() => {
                                                handelclearfile();
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField id="outlined-basic" value={count} type="number" onChange={(e) => setCount(e.target.value)} InputLabelProps={{
                            style: {
                                fontStyle: 'italic',
                                color: 'grey',
                                fontSize: 15
                            }
                        }} label="Split Count" variant="standard" size="small" />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField id="outlined-basic" value={path} onChange={(e) => setPath(e.target.value)} InputLabelProps={{
                            style: {
                                fontStyle: 'italic',
                                color: 'grey',
                                fontSize: 15
                            }
                        }} label="input location Path" variant="standard" size="small" />
                    </Box>
                    <Typography mt={2} fontSize={14} fontStyle='italic' color={'gray'}>eg. \\ogaitechserver01\lt09\2023\05_May\05-19-2023\Mineral Tree 28350\01_Input\</Typography>
                    <Box sx={fieldContainer}>
                        {load ? <Button
                            variant="contained"
                            size="small"
                            component="span"
                            sx={{
                                marginTop: 1,
                                textTransform: "capitalize",
                                backgroundColor: "#3c82ea",
                                fontFamily: "Lato",
                                borderRadius: "15px",
                                px: 8,
                                py: 1.5,
                            }}
                        >
                            <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} />
                        </Button>
                            :
                            <Button
                                variant="contained"
                                size="small"
                                component="span"
                                onClick={() => handelSubmit()}
                                sx={{
                                    marginTop: 1,
                                    textTransform: "capitalize",
                                    backgroundColor: "#3c82ea",
                                    fontFamily: "Lato",
                                    borderRadius: "15px",
                                    px: 8,
                                    py: 1.5,
                                }}
                            >
                                Submit
                            </Button>}
                    </Box>

                    {isError ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Alert severity="error">{isError}</Alert>
                            <CloseIcon
                                sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                                onClick={() => {
                                    setIsError(false);
                                }}
                            />
                        </Box>
                        : null}
                </Card>
            </Box>
        </Box>
    );
}

export default SplitFileScreen;
