import { Card } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function Home() {
  const { navBarOpen } = useSelector((state) => state.normal);
  return (
    <div>
      <Card
        sx={{
          marginLeft: navBarOpen ? "240px" : "0rem",
          transition: "width 5s",
          transitionTimingFunction: "ease",
          transitionDuration: "41s",
        }}
      >
        {" "}
        Home
      </Card>
    </div>
  );
}

export default Home;
