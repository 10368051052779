import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { COLORS } from '../constants/colors';

const CuDataGrid = styled(DataGrid)({
    ".MuiDataGrid-row": {
        cursor: 'pointer'
    }
})

const InvoiceDocumentTable = ({ data, columns }) => {
    // const navigate = useNavigate()
    const ValueGetterGrid = () => {
        return (
            <div style={{ height: '100%', width: '100%', background: COLORS.common.white }}>
                <CuDataGrid
                    autoHeight
                    getRowId={(option) => option.id}
                    rows={data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    style={{ padding: 5 }}
                // checkboxSelection
                // components={{
                //     Toolbar: GridToolbar,
                // }}
                // onRowClick={(v,e)=>{
                //     if (e.target.localName === 'div') navigate(`/update-task/${v?.row?.taskId}`)
                // }}
                // disableSelectionOnClick

                />
            </div>
        );
    }
    return (
        <>
            {ValueGetterGrid()}
        </>
    )
}
export default InvoiceDocumentTable;