import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { Alert, Button, TextField } from "@mui/material";
import { IMAGES } from "../constants/images";
import { useSelector } from "react-redux";
import { updateCommonStates, updateinvoiceFormStates } from "../store/actions";
import CustomizedDialogs from "./dialog";
import AddIcon from "@mui/icons-material/Add";
import "./components.css";
import copy from "../asstes/images/copy.png";
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles({
//   input: {
//     width: 400,
//     height: 150,
//     '& input + fieldset': {
//       borderColor: 'hotpink',
//     },
//   },
// });
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { COLORS } from "../constants/colors";
import { Delete } from "@mui/icons-material";

const CuDataGrid = styled(DataGrid)({
  ".MuiDataGrid-row": {
    cursor: "pointer",
  },
});
export default function NavBar({ selectedJob }) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tableData, currentPosition } = useSelector((state) => state.invoice);
  const { multipleCopy } = useSelector((state) => state.common);

  const [keyCode, setKeycode] = React.useState({
    key: "",
    keyCode: "",
    value: "",
  });
  const columns = [
    {
      field: "key",
      headerName: "CTRL + SHIFT + (Key)",
      align: "left",
      headerAlign: "start",
      width: 200,
    },
    {
      field: "value",
      headerName: "Value",
      align: "left",
      headerAlign: "start",
      minWidth: 1000,
      editable: true,
    },
  ];
  const onCellEditCommit = ({ id, field, value }) => {
    let t = [...multipleCopy];
    t.map((item) => console.log(item.keyCode, "dgfhvjbkeyCode"));
    const index = t.findIndex((item) => item.keyCode === id);
    console.log(id, index, "dgfhvjb");
    if (index >= 0) {
      const t2 = { ...t[index] };
      t2.value = value;
      t.splice(index, 1, t2);
      updateCommonStates("multipleCopy", [...t]);
      console.log(t, "dgfhvjb");
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNew = () => {
    updateinvoiceFormStates("currentPosition", null);
  };
  const defaultProps = {
    options: tableData,
    getOptionLabel: (option) => option.batch,
  };
  const [isExist, setIsExist] = React.useState(false);
  const triggerAlredyExistAlert = () => {
    setIsExist(true);
    setTimeout(() => setIsExist(false), 2000);
  };
  const onSubmit = () => {
    const find = multipleCopy?.find((item) => item.keyCode === keyCode.keyCode);
    if (find) return triggerAlredyExistAlert();
    updateCommonStates("multipleCopy", [...multipleCopy, keyCode]);
    setKeycode({
      key: "",
      keyCode: "",
      value: "",
    });
  };
  const [selectedRow, setSelectedRow] = React.useState([]);
  const deleteItems = () => {
    const updatedItems = multipleCopy?.filter(
      (item) => !selectedRow.includes(item.keyCode)
    );
    updateCommonStates("multipleCopy", [...updatedItems]);
    selectedRow([]);
  };
  return (
    <Box sx={{ p: 0 }}>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar position="static" sx={{ backgroundColor: "#5a5555" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
            <Avatar className="App-logo" src={IMAGES.logo} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Adhanam
          </Typography> */}

          <Box>
            {" "}
            <Typography sx={{ fontSize: "14px" }}>
              Job Name:<span style={{ fontSize: "12px" }}>  {selectedJob?.jobId}</span>
            </Typography>
          </Box>
          <Box>
            {" "}
            <Typography sx={{ fontSize: "14px" }}>
              Batch No:<span style={{ fontSize: "12px" }}></span>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              Doc Count:<span style={{ fontSize: "12px" }}>  {(currentPosition || currentPosition ==0)  ? currentPosition + 1 : ''} of {tableData?.length || ''}</span>
            </Typography>
          </Box>

          {/* <a href='#bottom'>click</a> */}
          {/*Select code */}
          {/* <Box className="d-flex justify-center align-items-center me-3">
            <Paper
              sx={{
                p: "0px 4px",
                display: "flex",
                alignItems: "center",
                width: 300,
                boxShadow: STYLES.boxShadow.white,
                height: 30,
              }}
            >
              <Autocomplete
                fullWidth
                options={tableData}
                getOptionLabel={(option) => option.pdfName}
                id="clear-on-escape"
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    size="small"
                    placeholder="Search"
                    {...params}
                    variant="standard"
                  />
                )}
                style={{ width: 300 }}
                value={
                  currentPosition !== null && tableData?.length
                    ? tableData[currentPosition]?.pdfName ===
                      selectBatch?.pdfName
                      ? selectBatch
                      : null
                    : null
                }
                onChange={(e, i) => {
                  setSelectBatch(i);
                  console.log();
                  const index = tableData?.findIndex(
                    (item) => item?.pdfName === i?.pdfName
                  );
                  index >= 0 &&
                    updateinvoiceFormStates("currentPosition", index);
                }}
                disableCloseOnSelect={false}
              />
            </Paper>
          </Box> */}

          {/* <Box className="d-flex">
            <Box className="me-2">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                disabled={tableData?.length === 0 || currentPosition === 0}
                onClick={onPrev}
                title="Prev"
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box className="me-2">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                disabled={tableData?.length === 0 || currentPosition === tableData?.length - 1 || currentPosition === null}
                onClick={onNext}
                title="Next"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
            {/* <Box className="me-2">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={onNew}
                title="Create new"
                disabled={currentPosition === null}
              >
                <NoteAddIcon />
              </IconButton>
            </Box> */}
          {/* </Box>  */}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClickOpenDialog}
            color="inherit"
          >
            {/* <ContentCopyIcon /> */}
            <img src={copy} style={{ height: 25 }} alt="copy" />
          </IconButton>
          {/* {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={LogoutFun}>Logout</MenuItem>
                // <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )} */}
        </Toolbar>
      </AppBar>
      <CustomizedDialogs
        title={"Multiple Copy & Paste"}
        open={open}
        handleClose={handleCloseDialog}
        maxWidth={"lg"}
      >
        <Box className="copy-dialog-container">
          {isExist ? (
            <Alert severity="error">This Key already saved!</Alert>
          ) : (
            false
          )}
          <Box className="d-flex align-items-center">
            <img className="copy-dialog-image" src={IMAGES.ctrl} />
            <p className="copy-dialog-add">+</p>
            <img className="copy-dialog-image" src={IMAGES.shift} />
            <p className="copy-dialog-add">+</p>
            <TextField
              inputProps={{
                style: {
                  height: 40,
                  width: 40,
                  padding: 0,
                  textAlign: "center",
                },
              }}
              // onChange={(e) => console.log(e,'eee')}
              value={keyCode.key}
              onKeyDown={(e) => {
                console.log(String.fromCharCode(e.keyCode), "eee1");
                console.log(e.key, "eee1");
                setKeycode({ ...keyCode, key: e.key, keyCode: e.keyCode });
              }}
            />
            <p className="copy-dialog-add">=</p>
            <Box className="flex-1 me-1">
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    height: 40,
                    padding: 0,
                    paddingLeft: 10,
                  },
                }}
                value={keyCode.value}
                onChange={(e) => {
                  setKeycode({ ...keyCode, value: e.target.value });
                }}
              />
            </Box>
            <Button
              onClick={onSubmit}
              color="success"
              variant="contained"
              sx={{ minWidth: 35, width: 35 }}
            >
              <AddIcon />
            </Button>
          </Box>
          {/* {multipleCopy?.length ? <Box className="mt-2"> 
            <CustomizedTables header={[{ title: 'CTRL + SHIFT + (Key)', field: 'key' }, { title: 'Value', field: 'value' }]} data={multipleCopy} />
          </Box> : null} */}
          {selectedRow?.length && multipleCopy.length ? (
            <Button onClick={deleteItems} variant="contained" color="error">
              <Delete />
            </Button>
          ) : null}
          {multipleCopy?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                background: COLORS.common.white,
              }}
            >
              <CuDataGrid
                autoHeight
                getRowId={(option) => option.keyCode}
                rows={multipleCopy}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                style={{ padding: 5 }}
                onCellEditCommit={onCellEditCommit}
                checkboxSelection
                // disableRowSelectionOnClick
                disableSelectionOnClick
                onSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRow(newRowSelectionModel);
                }}
              // isCellEditable={(params) => console.log(params,'params')}
              />
            </div>
          ) : null}
        </Box>
      </CustomizedDialogs>
    </Box>
  );
}
