import { updateStyleReducer } from "./reducers/styleReducer";
import { updateCommonReducer } from "./reducers/commonReducer";
import { updateComparsTable, updateInvoiceFormReducer } from "./reducers/invoiceFormReducer";
import { updateLoginReducer } from "./reducers/loginReducer";
import store from "./store";
import { updateJobFormReducer } from "./reducers/JobReducer";

export const updateinvoiceFormStates = (stateName, value) => {
  store.dispatch(updateInvoiceFormReducer({ name: stateName, value: value }));
};
export const updateLoginStates = (stateName, value) => {
  store.dispatch(updateLoginReducer({ name: stateName, value: value }));
};
export const updateCommonStates = (stateName, value) => {
  store.dispatch(updateCommonReducer({ name: stateName, value: value }));
};
export const updateStyleStates = (stateName, value) => {
  store.dispatch(updateStyleReducer({ name: stateName, value: value }));
};
export const updateJobFormStates = (stateName, value) => {
  store.dispatch(updateJobFormReducer({ name: stateName, value: value }));
};
