import { createSlice } from "@reduxjs/toolkit";

export const login = createSlice({
  name: "login",
  initialState: {
    isLogin: false,
    empId: null,
    count: 20,
    isValidate: false,
    users: {},
  },
  reducers: {
    updateLoginReducer: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { updateLoginReducer } = login.actions;

export default login.reducer;
