

export const validationMessages = {
    required: 'This is required field',
    invoiceForm: {
        addPdfExtn: 'Include .pdf at end of the name',
        dateFormat: 'Please enter valid date eg.1995-05-19',
        amountTotalNotmatch: 'Amount mismatch',
        alphaNum:'Must contain Numeric values'
    },
}