import axiosInterceptors from "./interceptorAxios";
import axios from 'axios'
const baseURL = "http://192.168.1.125:8080/api";
export const getVendorNameListService = async (data) => {
    console.log(data);
    const config = {
        method: "get",
        url: `${baseURL}/master/`,
        // withCredentials: true
    };

    return await axiosInterceptors(config)
        .then((response) => {
            console.log(response, '4567rftyvhjbkjn');
        })
        .catch(function (error) {

        });
};

export const uploadVendorNameFileService = async (file) => {

    const formData = new FormData();
    formData.append('file', file);
    const config = {
        method: "post",
        url: `${baseURL}/master/vendorName/upload`,
        formData
        // withCredentials: true
    };

    return await axios.post(`${baseURL}/master/vendorName/upload`,formData)
        .then((response) => {
            console.log(response, '4567rftyvhjbkjn');
        })
        .catch(function (error) {

        });
};