import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LogoutFun } from "../utils/functions";
import home from "../asstes/images/home.png";
import boxWhite from "../asstes/images/boxwhite.png";
import { updateCommonStates, updateStyleStates } from "../store/actions";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)} + 0px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(!open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function HeadNavbar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const matches = useMediaQuery("(max-width:800px)");
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { user } = useSelector((state) => state.login);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const nav = useNavigate();
  const handleLogout = () => {
    setAnchorEl(null);
    nav("/login");
  };
  const handleDrawerOpen = () => {
    if (!open) {
      setOpen(true);
      updateStyleStates("navBarOpen", true);
    } else {
      updateStyleStates("navBarOpen", false);

      setOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handeloption = (e, number) => {
    setSelectedIndex(number);
    if (e === "Home") {
      nav("/");
    }
    if (e === "Job") {
      nav("/job");
    }
    if (e === "From") {
      nav("/from");
    }
    if (e === "Assign") {
      nav("/assign");
    }
    if (e === "compare") {
      nav("/compare");
    }
    if (e === "split") {
      nav("/split");
    }
    if (e === "combine") {
      nav("/combine");
    }
    if (e === "merge") {
      nav("/merge");
    }
    if (e === "final") {
      nav("/final");
    }
    if (e === "VendorMaster") {
      nav("/master/vendor");
    }
    if (e === 'qc') nav("/qc");
    if (e === "AddDpartment") {
      dispatch({
        type: "PAGE_OPTION",
        payload: 4,
      });
    }
    if (e === "Add Team") {
      dispatch({
        type: "PAGE_OPTION",
        payload: 6,
      });
    }
    if (e === "Add Project") {
      dispatch({
        type: "PAGE_OPTION",
        payload: 5,
      });
    }
    if (e === "Doc View") {
      dispatch({
        type: "PAGE_OPTION",
        payload: 7,
      });
    }
  };
  React.useEffect(() => {
    dispatch({
      type: "PAGE_OPTION",
      payload: 0,
    });
  }, []);
  React.useEffect(() => {
    if (matches) {
      setOpen(false);
    }
  }, [matches]);
  const textStyle = {
    fontFamily: "Lato",
  };
  const navRef = React.useRef();
  console.log(navRef.current?.clientHeight, 'navRef.current?');
  return (
    <Box style={{ marginBottom: navRef.current?.clientHeight || 64, paddingBottom: 1 }}>
      <CssBaseline />
      <AppBar ref={navRef} position="fixed" sx={{ backgroundColor: "#353535" }} open={open}>
        {/* sx={{ backgroundColor: "#353535" }}  */}
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                // ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={() => {
                nav("/");
              }}
            >
              <img src={home} style={{ height: 25 }} alt="home" />
            </Box>
          </Box>
          <Box>
            <IconButton color="inherit">
              <AccountCircleIcon
                sx={{ fontSize: "33px" }}
                onClick={handleMenu}
              />{" "}
              <Typography sx={{ fontSize: "14px" }}>
                {user?.userName || ""}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              //   anchorOrigin={{
              //     vertical: "top",
              //     horizontal: "right",
              //   }}
              keepMounted
              //   transformOrigin={{
              //     vertical: "top",
              //     horizontal: "right",
              //   }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  nav("/");
                  LogoutFun();
                }}
              >
                Logout
              </MenuItem>
              {/* <MenuItem onClick={handleDrawerClose}>My account</MenuItem> */}
            </Menu>
          </Box>
          <Box>
            <IconButton color="inherit">
              <img
                src={boxWhite}
                style={{ height: 25, objectFit: "contain" }}
                alt="logo"
              />
            </IconButton>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={LogoutFun}>Logout</MenuItem>
              <MenuItem onClick={handleDrawerClose}>My account</MenuItem>
            </Menu> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
{/* 
          <ListItem
            disablePadding
            onClick={() => handeloption("Job", 1)}
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 1 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 1}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primary={"Create JOB"}
                primaryTypographyProps={textStyle}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => handeloption("Assign", 2)}
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 2 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 2}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primary={"Assignment"}
                primaryTypographyProps={textStyle}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("compare", 4)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 4 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 4}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primary={"Compare"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 5 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 5}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Report"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
          
          <ListItem
            onClick={() => handeloption("split", 6)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 6 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 6}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Split File"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>


          <ListItem
            disablePadding
            onClick={() => handeloption("qc", 3)}
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 3 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 3}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              {/* <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AddTaskIcon />
              </ListItemIcon> */}
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"QCH"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("combine", 7)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 7 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 7}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Combine File"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("merge", 8)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 8 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 8}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Amount Sheet Merge"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("final", 9)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 9 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 9}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center", 
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Dispatch Sheet"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            onClick={() => handeloption("VendorMaster", 10)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
              backgroundColor: selectedIndex === 10 ? "rgb(62 216 241 / 87%)" : "",
            }}
          >
            <ListItemButton
              selected={selectedIndex === 10}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center", 
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Master"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem
            onClick={() => {
              nav("/");
              LogoutFun();
            }}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
            }}
          >
            <ListItemButton
              selected={selectedIndex === 8}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primaryTypographyProps={textStyle}
                primary={"Logout"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            onClick={() => handeloption("Role", 4)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
            }}
          >
            <ListItemButton
              selected={selectedIndex === 4}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AcUnitIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Assign Role"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("Doc", 5)}
            disablePadding
            variant=""
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
            }}
          >
            <ListItemButton
              selected={selectedIndex === 5}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DriveFolderUploadIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Share Document"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => handeloption("Doc View", 6)}
            disablePadding
            sx={{
              display: "block",
              ":hover": {
                backgroundColor: "rgb(62 216 241 / 87%)",
              },
            }}
          >
            <ListItemButton
              selected={selectedIndex === 6}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <StreetviewIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Document View"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
      </Drawer>
    </Box>
  );
}
export default HeadNavbar;
