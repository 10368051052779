import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import file from "../asstes/images/file.png";
import upload from "../asstes/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from 'xlsx';
import CustomizedTables from "../components/table";
import { updateinvoiceFormStates } from "../store/actions";
import { useNavigate } from "react-router-dom";
var _ = require('lodash');

function Compare() {
  const inputRef = useRef(null);
  const input1 = useRef(null);
  const drop = useRef(null);
  const drop1 = useRef(null);
  const navigation = useNavigate();
  const [stateValues, setStateValues] = useState({
    key1: "",
    key2: "",
  });
  const [comperfile, setComperfile] = useState(null);
  const [comperfile1, setComperfile1] = useState(null);
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);

  const [firstkey1, setfirstkey1] = useState("");
  const [firstkey2, setfirstkey2] = useState("");


  const labelStyle = {
    fontSize: "14px",
    p: 1,
    width: 100,
    fontFamily: "Lato",
  };
  const fieldContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: 2,
  };
  const ButtonStyle = {
    marginTop: 1,
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#3c82ea",
    borderRadius: "15px",
    px: 3,
  };
  const iconStyle = {
    color: "#81b3fd",
  };
  const handelclearfile = () => {
    setLoad(false);
    drop.current.value = "";
    inputRef.current.value = "";
    setStateValues({ ...stateValues, key1: "" });
  };
  const handelclearfile1 = () => {
    setLoad(false);
    drop1.current.value = "";
    input1.current.value = "";
    setStateValues({ ...stateValues, key2: "" });
  };
  const convertExcelToJson = (file, callBack) => {
    try {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: 'buffer' });
          // const wsName = wb.SheetNames[0];
          const wsName = wb.SheetNames[1];
          console.log(wsName, 'wb.SheetNameswb.SheetNameswb.SheetNames');
          const ws = wb.Sheets[wsName];
          const data = XLSX.utils.sheet_to_json(ws)
          resolve(data);
          fileReader.onerror = (e) => {
            reject(e)
          }
        }
      })
      promise.then(res => {
        callBack && callBack(res)
      }).catch(() => setLoad(false))

    } catch (error) {
      setLoad(false);
    }
  }
  const hangelFile = (e) => {
    setLoad(true);
    const file = e.target?.files[0];
    setStateValues({ ...stateValues, key1: file });
    convertExcelToJson(file, (res) => setComperfile(res))
    setLoad(false);
  };
  const hangelFile1 = (e) => {
    setLoad1(true);
    const file = e.target?.files[0]
    setStateValues({ ...stateValues, key2: file });
    convertExcelToJson(file, (res) => setComperfile1(res))
    setLoad1(false);
  };
  // const compareArrays = (a, b) =>
  //   a.length === b.length && a.every((element, index) => element === b[index]);
  const [isError, setIsError] = useState(false);
  const [errorTable, setErrorTable] = useState([]);
  const showError = (msg) => {
    setIsError(msg);
    setTimeout(() => setIsError(null), 2000)
  }
  const handelSubmit = async () => {
    console.log(comperfile, 'comperfile');
    console.log(comperfile1, 'comperfile1');
    updateinvoiceFormStates('tableData', []);
    updateinvoiceFormStates('compareTableData', [])
    if ((comperfile?.length !== comperfile1?.length) && comperfile && comperfile1) return showError('Number of rows are not matching');
    const comperfileFinal = comperfile?.map((item) => {
      const t = { ...item };
      t.Ramount = item.Ramount?.split(',');
      t.Rcost = item.Rcost?.split(',');
      t.Rmemo = item.Rmemo?.split(',');
      t.Rquantity = item.Rquantity?.split(',');
      return t
    })
    const comperfile1Final = comperfile1?.map((item) => {
      const t = { ...item };
      t.Ramount = item.Ramount?.split(',');
      t.Rcost = item.Rcost?.split(',');
      t.Rmemo = item.Rmemo?.split(',');
      t.Rquantity = item.Rquantity?.split(',');
      return t
    })
    console.log(comperfileFinal, 'comperfileFinalhdtfjcht');
    console.log(comperfile1Final, 'comperfileFinalhdtfjcht11111');
    updateinvoiceFormStates('tableData', [...comperfileFinal]);
    updateinvoiceFormStates('compareTableData', [...comperfile1Final]);
    navigation('/compare/doc');


    // const errors = [];
    // const promise = new Promise((resolve, reject) => {
    //   const t = comperfile?.map(async (item, index) => {
    //     const result = await _.pickBy(comperfile[index], (v, k) => !_.isEqual(comperfile1[index][k], v))
    //     // console.log(result,comperfile[index].__rowNum__,'resultresultresult');
    //     Object.keys(result)?.map(i => {
    //       const t = {
    //         name: i,
    //         value: result[i],
    //         row: comperfile[index].__rowNum__,
    //         column: 1
    //       }
    //       errors.push(t);
    //       return t
    //     })
    //   })
    //   resolve(t)
    // })
    // promise.then((res) => setErrorTable(errors)).catch(err => console.log(errors, 'errorserrorserrorserrors'))

    // if (compareArrays(comperfile, comperfile1)) {
    // }
  };
  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);
  React.useEffect(() => {
    drop1.current.addEventListener("dragover", handleDragOver1);
    drop1.current.addEventListener("drop", handleDrop1);
    return () => {
      drop1?.current?.removeEventListener("dragover", handleDragOver1);
      drop1?.current?.removeEventListener("drop", handleDrop1);
    };
  }, []);
  console.log(firstkey1, firstkey2);
  console.log(stateValues);
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      console.log(files, '12345');
      setfirstkey1(files[0]);
    }
  };
  const handleDrop1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      console.log(files);
      setfirstkey2(files[0]);
    }
  };
  useEffect(() => {
    if (firstkey2) {
      try {
        setLoad(true);
        setStateValues({ ...stateValues, key2: firstkey2 });
        convertExcelToJson(firstkey2, (res) => setComperfile1(res));
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    }
  }, [firstkey2]);
  useEffect(() => {
    if (firstkey1) {
      try {
        setLoad(true);
        setStateValues({ ...stateValues, key1: firstkey1 });
        convertExcelToJson(firstkey1, (res) => setComperfile(res));
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    }
  }, [firstkey1]);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", overflowY: 'scroll' }}>
      <Box sx={{ pt: 2 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Lato",
            fontSize: "1.5rem",
            p: 1,
          }}
        >
          Compare
        </Typography>
        <Card
          sx={{ p: 4, backgroundColor: "#f0fefc", border: "1px solid #3c82ea" }}
        >
          <Box ref={drop} sx={fieldContainer}>
            {" "}
            <Typography sx={labelStyle}>Key1</Typography>
            <Box
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                accept=".xlsx"
                id="select-image"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(e) => hangelFile(e)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <label htmlFor="select-image">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    sx={ButtonStyle}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          pr: 2,
                          alignItems: "center",
                        }}
                      >
                        <img src={upload} style={{ height: 25 }} alt="uplaod" />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ fontSize: "16px", fontFamily: "Lato" }}
                        >
                          Select file
                        </Typography>
                        <Typography
                          sx={{ fontSize: "10px", fontFamily: "Lato" }}
                        >
                          or Drag and Drop
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </label>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      textAlign: "justify",
                      fontSize: "12px",
                    }}
                  >
                    {stateValues?.key1?.name ? stateValues?.key1?.name : null}
                  </Typography>
                  {stateValues.key1 ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                      onClick={() => {
                        handelclearfile();
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box ref={drop1} sx={fieldContainer}>
            {" "}
            <Typography sx={labelStyle}>Key2</Typography>
            <Box
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                accept=".xlsx"
                id="select-ima"
                style={{ display: "none" }}
                ref={input1}
                onChange={(e) => hangelFile1(e)}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 400,
                }}
              >
                <label htmlFor="select-ima">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    sx={ButtonStyle}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          pr: 2,
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <img src={upload} style={{ height: 25 }} alt="uplaod" />
                      </Box>

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ fontSize: "16px", fontFamily: "Lato" }}
                        >
                          Select file
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                          }}
                        >
                          or Drag and Drop
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </label>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      textAlign: "justify",
                      fontSize: "12px",
                    }}
                  >
                    {stateValues?.key2?.name ? stateValues?.key2?.name : null}
                  </Typography>
                  {stateValues.key2 ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", pl: 1, color: "#eb4e3d" }}
                      onClick={() => {
                        handelclearfile1();
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={fieldContainer}>
            <Typography sx={labelStyle}></Typography>
            {load ? <Button
              variant="contained"
              size="small"
              component="span"
              onClick={() => handelSubmit()}
              sx={{
                marginTop: 1,
                textTransform: "capitalize",
                backgroundColor: "#3c82ea",
                fontFamily: "Lato",
                borderRadius: "15px",
                px: 8,
                py: 1.5,
              }}
            >
            <CircularProgress size={20} style={{ color: '#fff' }} sx={{ mr: 2 }} />
            </Button>
              :
              <Button
                variant="contained"
                size="small"
                component="span"
                onClick={() => handelSubmit()}
                sx={{
                  marginTop: 1,
                  textTransform: "capitalize",
                  backgroundColor: "#3c82ea",
                  fontFamily: "Lato",
                  borderRadius: "15px",
                  px: 8,
                  py: 1.5,
                }}
              >
                Submit
              </Button>}
          </Box>

          {isError ? <Alert severity="error">{isError}</Alert> : null}
        </Card>
      </Box>
    </Box>
  );
}

export default Compare;
